const state = () => ({
  lastJobsPage: 'jobs'
})

const mutations = {
  setLastJobsPage(state, value) {
    state.lastJobsPage = value
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
