<template>
  <footer class="app-footer">
    <b-container class="app-footer__container text-center">
      <p>
        We help startups grow - Made with &#10084;&#65039; by
        <a target="_blank" :href="thehubUrl">thehub.io</a>
      </p>
    </b-container>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
  computed: {
    thehubUrl() {
      return process.env.BASE_URL
    }
  }
}
</script>

<style scoped lang="scss">
.app-footer {
  position: relative;
  background-color: $gray-900;
  color: $white;
  overflow: hidden;

  &__container {
    position: relative;
    max-width: rem(1000px);
    padding: 80px 0;

    a {
      text-decoration: underline;
      &:hover {
        color: white;
        text-decoration: none;
      }
    }
  }
}
</style>
