<template>
  <SlideUpDown timing-function="ease-out" :duration="200" :active="show">
    <div class="pb-30">
      <b-alert show :variant="variant" style="margin: 0;">
        <slot />
      </b-alert>
    </div>
  </SlideUpDown>
</template>

<script>
import SlideUpDown from 'vue-slide-up-down'

export default {
  name: 'AlertSlideUpDown',
  components: {
    SlideUpDown
  },
  props: {
    show: Boolean,
    variant: {
      type: String,
      default: 'danger'
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .alert-success {
  border-color: $green;
  color: #109e6d;
}
</style>
