export const jobRoleVisuals = {
  backenddeveloper: {
    icon: 'development',
    color: '#f71f3e'
  },
  devops: {
    icon: 'development',
    color: '#f71f3e'
  },
  frontenddeveloper: {
    icon: 'development',
    color: '#f71f3e'
  },
  fullstackdeveloper: {
    icon: 'development',
    color: '#f71f3e'
  },
  mobiledevelopment: {
    icon: 'mobiledevelopment',
    color: '#d93adf'
  },
  businessdevelopment: {
    icon: 'businessdevelopment',
    color: '#4440db'
  },
  operations: {
    icon: 'bi',
    color: '#fa8457'
  },
  customerservice: {
    icon: 'customerservice',
    color: '#65efe2'
  },
  customersuccess: {
    icon: 'customerservice',
    color: '#65efe2'
  },
  design: {
    icon: 'design',
    color: '#fa8457'
  },
  marketing: {
    icon: 'marketing',
    color: '#FFB300'
  },
  projectmanagement: {
    icon: 'projectmanagement',
    color: '#50d6e4'
  },
  sales: {
    icon: 'sales',
    color: '#23CE6B'
  },
  cxo: {
    icon: 'bi',
    color: '#fa8457'
  },
  engineer: {
    icon: 'development',
    color: '#f71f3e'
  },
  finance: {
    icon: 'businessdevelopment',
    color: '#fa8457'
  },
  humanresources: {
    icon: 'bi',
    color: '#fa8457'
  },
  datascience: {
    icon: 'businessdevelopment',
    color: '#fa8457'
  },
  legal: {
    icon: 'businessdevelopment',
    color: '#fa8457'
  },
  analyst: {
    icon: 'businessdevelopment',
    color: '#fa8457'
  },
  productmanagement: {
    icon: 'projectmanagement',
    color: '#50d6e4'
  },
  qualityassurance: {
    icon: 'businessdevelopment',
    color: '#fa8457'
  },
  uxuidesigner: {
    icon: 'design',
    color: '#fa8457'
  },
  other: {
    icon: 'sales',
    color: '#23CE6B'
  }
};

export const interestedStatus = [
  { value: 'applied', label: 'Applied', icon: 'arrow', isLocked: false },
  {
    value: 'custom',
    label: 'Custom',
    icon: 'arrow',
    isLocked: false
  },
  { value: 'hired', label: 'Hired', icon: 'arrow', isLocked: false },
  { value: 'toreject', label: 'To be rejected', icon: 'dash', isLocked: false },
  { value: 'rejected', label: 'Rejected', icon: 'blank', isLocked: true }
];

export const companyPerkIcons = {
  beer: require('@/assets/components/startups/perks/beer.svg'),
  central: require('@/assets/components/startups/perks/central.svg'),
  coffee: require('@/assets/components/startups/perks/coffee.svg'),
  'daily-allowance': require('@/assets/components/startups/perks/daily-allowance.svg'),
  equity: require('@/assets/components/startups/perks/equity.svg'),
  'gamified-office': require('@/assets/components/startups/perks/gamified-office.svg'),
  gear: require('@/assets/components/startups/perks/gear.svg'),
  gym: require('@/assets/components/startups/perks/gym.svg'),
  healthcare: require('@/assets/components/startups/perks/healthcare.svg'),
  leave: require('@/assets/components/startups/perks/leave.svg'),
  lunch: require('@/assets/components/startups/perks/lunch.svg'),
  mental: require('@/assets/components/startups/perks/mental.svg'),
  'paid-holiday': require('@/assets/components/startups/perks/paid-holiday.svg'),
  pension: require('@/assets/components/startups/perks/pension.svg'),
  pet: require('@/assets/components/startups/perks/pet.svg'),
  remote: require('@/assets/components/startups/perks/remote.svg'),
  skills: require('@/assets/components/startups/perks/skills.svg'),
  social: require('@/assets/components/startups/perks/social.svg'),
  'soft-drink': require('@/assets/components/startups/perks/soft-drink.svg'),
  time: require('@/assets/components/startups/perks/time.svg'),
  transport: require('@/assets/components/startups/perks/transport.svg'),
  'unlimited-holiday': require('@/assets/components/startups/perks/unlimited-holiday.svg'),
  welness: require('@/assets/components/startups/perks/welness.svg'),
  'work-life': require('@/assets/components/startups/perks/work-life.svg')
};

export const salaryOptions = [
  { value: 'competitive', text: 'Competitive' },
  { value: 'unpaid', text: 'Unpaid' },
  { value: 'range', text: 'Range' }
];

export const equityOptions = [
  { value: 'undisclosed', text: 'Undisclosed' },
  { value: 'negotiated', text: 'To be negotiated' },
  { value: 'percentage', text: 'Percentage' }
];

export const priceOptions = [
  { countryCode: 'DK', amount: '599', text: 'DKK', symbol: 'kr.' },
  { countryCode: 'NO', amount: '879', text: 'NOK', symbol: 'kr.' },
  { countryCode: 'SE', amount: '849', text: 'SEK', symbol: 'kr.' },
  { countryCode: 'FI', amount: '79', text: 'EUR', symbol: '€' },
  { countryCode: 'IS', amount: '11500', text: 'ISK', symbol: 'kr.' },
  { countryCode: 'EU', amount: '79', text: 'EUR', symbol: '€' },
  { countryCode: 'other', amount: '79', text: 'EUR', symbol: '€' }
];

export const emailFrequency = [
  { value: 'daily', label: 'Daily' },
  { value: 'onceweek', label: 'Weekly' }
];

export const investorAreas = [
  {
    value: 'consumer',
    label: 'Consumer services & devices'
  },
  {
    value: 'financial',
    label: 'Financial'
  },
  {
    value: 'greentech',
    label: 'Energy & Greentech'
  },
  {
    value: 'hardware',
    label: 'Hardware'
  },
  {
    value: 'healthcare',
    label: 'Healthcare'
  },
  {
    value: 'impact',
    label: 'Impact'
  },
  {
    value: 'marketplace',
    label: 'Marketplace & eCommerce'
  },
  {
    value: 'media',
    label: 'Media'
  },
  {
    value: 'mobile',
    label: 'Mobile'
  },
  {
    value: 'other',
    label: 'Other'
  },
  {
    value: 'retail',
    label: 'Retail'
  },
  {
    value: 'software',
    label: 'Software'
  }
];

export const investorRequireInvestments = ['ANGEL', 'ANGELS', 'VENTURES'];
