const { CancelToken } = require('axios')

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
  async file(context, { type, file, progress }) {
    const formData = new FormData()
    formData.append('file_upload', file)

    const source = CancelToken.source()

    const upload = {
      source
    }

    const request = this.$axios.$post(`/upload/${type}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      cancelToken: source.token,
      onUploadProgress(e) {
        progress && progress(e)
      }
    })

    upload.request = request

    return upload
  },
  async uploadImage(context, payload) {
    const formData = new FormData()
    formData.append('file_upload', payload.file)

    const { data } = await this.$axios.post(`/upload/image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    return data
  }
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
