<template>
  <BaseLayout>
    <content>
      <nuxt />
    </content>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/layouts/base'

export default {
  name: 'PlainLayout',
  components: {
    BaseLayout
  }
}
</script>
