import Vue from 'vue'

const engagement = {
  methods: {
    $engagement(data) {
      const userCurrentProfile = this.$store.getters['user/currentProfile']

      if (userCurrentProfile) {
        if (userCurrentProfile.type === 'company') {
          data.company = userCurrentProfile.id
        } else if (userCurrentProfile.type === 'investor') {
          data.investor = userCurrentProfile.id
        }
      }

      this.$axios.$post('engagement/log', data).catch((err) => {
        console.error(err)
      })
    }
  }
}

Vue.mixin(engagement)
