export default function (ctx, inject) {
  const layer = 'dataLayer'
  const initialized = {}
  ctx.$gtm = {
    init(id) {
      if (initialized[id] || !window._gtm_inject) {
        return
      }
      window._gtm_inject(id)
      initialized[id] = true
    },
    push(obj) {
      if (!window[layer]) {
        window[layer] = [{ 'gtm.start': new Date().getTime(), event: 'gtm.js' }]
      }
      window[layer].push(obj)
    }
  }
  inject('gtm', ctx.$gtm)
}
