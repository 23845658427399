import BaseModel from './BaseModel'
import Image from './Image'
import InvestorKeyPeople from './InvestorKeyPeople'
import InvestorInvestment from './InvestorInvestment'
import Numeral from 'numeral'
import { mapValues } from 'lodash'

export default class Investor extends BaseModel {
  constructor(...args) {
    InvestorInvestment.mapping.company = Investor.mapping.company

    super(...args)
  }

  get url() {
    return '/funding/' + this.key
  }

  get pitchUrl() {
    return '/investors/' + this.key + '/send-pitch'
  }

  get adminUrl() {
    return '/client/investors/' + this.key
  }

  get prefersEmail() {
    return this.contactPreference === 'EMAIL'
  }

  get prefersWebsite() {
    return this.contactPreference === 'WEBSITE'
  }

  get logoImageUrl() {
    if (this.logoImage) {
      return this.logoImage.thumbnailSizeUrl
    }

    if (this.logo) {
      return this.logo.thumbnailSizeUrl
    }

    return null
  }

  get websiteUrl() {
    return this.website && this.website.match(/^https?:/)
      ? this.website
      : 'http://' + this.website
  }

  get hasImpactIndustry() {
    return this.industries && this.industries.indexOf('impact') !== -1
  }

  get description() {
    return this.intro || ''
  }

  get formattedViews() {
    return mapValues(this.views, (value) => {
      const len = Numeral(value || 0).format('0a')
      return len + ' view' + (len !== 1 ? 's' : '')
    })
  }
}

Investor.mapping = {
  logo: Image,
  logoImage: Image,
  smallImage: Image,
  coverImage: Image,
  keyPeople: InvestorKeyPeople,
  investments: InvestorInvestment
}
