export default function() {
  ;(function() {
    const s = document.getElementsByTagName('script')[0]
    const b = document.createElement('script')
    b.type = 'text/javascript'
    b.id = 'CookieConsent'
    b.setAttribute('data-culture', 'EN')
    b.src = 'https://policy.app.cookieinformation.com/uc.js'
    s.parentNode.insertBefore(b, s)
  })()

  console.log('Cookie Information installed')
}
