<template>
  <b-modal
    v-model="modal1"
    centered
    :title="customSignUpModalTitle || 'Let us know who you are'"
    modal-class="modal-bb-squared"
    footer-class="light-blue"
    v-on="$listeners"
  >
    <template slot="default">
      <div class="py-16">
        <button class="btn btn-lg btn-linkedin w-100" @click="onAuthorizeWithLinkedin">
          <span v-if="!isAuthorizingLinkedin"
            ><i class="icon-linkedin"></i><span>Sign up with LinkedIn</span></span
          >
          <b-spinner v-else small />
        </button>
      </div>
      <div class="pb-16">
        <button class="btn btn-lg btn-outline w-100" @click="onGotoSignUpWithEmail">
          Sign up with Email
        </button>
      </div>
    </template>
    <template slot="modal-footer">
      <div>
        <span>Already have an account? </span>
        <button class="btn btn-link fs14" @click="onGotoSignIn">Log in</button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'UserSignUpLandingModal',
  props: {
    isAuthorizingLinkedin: Boolean,
    onAuthorizeWithLinkedin: {
      type: Function,
      required: true
    },
    onGotoSignIn: {
      type: Function,
      required: true
    },
    onGotoSignUpWithEmail: {
      type: Function,
      required: true
    },
    customSignUpModalTitle: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      modal1: true
    };
  }
};
</script>
