/* global CookieInformation */
export default function({ $gtm }) {
  window.addEventListener(
    'CookieInformationConsentGiven',
    function() {
      if (CookieInformation.getConsentGivenFor('cookie_cat_marketing')) {
        console.log('GTM INIT!')

        $gtm.init(process.env.GTM_ID)
        // Place cookie-setting script here.
        // Or some other javascript function you want to fire on consent.
      }
    },
    false
  )
}
