const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['isApplicant'] = require('../middleware/isApplicant.js')
middleware['isApplicant'] = middleware['isApplicant'].default || middleware['isApplicant']

middleware['isClientManager'] = require('../middleware/isClientManager.js')
middleware['isClientManager'] = middleware['isClientManager'].default || middleware['isClientManager']

middleware['isInvestorManager'] = require('../middleware/isInvestorManager.js')
middleware['isInvestorManager'] = middleware['isInvestorManager'].default || middleware['isInvestorManager']

middleware['redirectCompanyDraftToForm'] = require('../middleware/redirectCompanyDraftToForm.js')
middleware['redirectCompanyDraftToForm'] = middleware['redirectCompanyDraftToForm'].default || middleware['redirectCompanyDraftToForm']

middleware['redirectInvestorDraftToForm'] = require('../middleware/redirectInvestorDraftToForm.js')
middleware['redirectInvestorDraftToForm'] = middleware['redirectInvestorDraftToForm'].default || middleware['redirectInvestorDraftToForm']

export default middleware
