import dayjs from 'dayjs';
import { mapValues } from 'lodash';
import BaseModel from './BaseModel';
import Company from './Company';
import Location from './Location';
import Image from '@/models/Image';
import Numeral from 'numeral';
import Board from './Board';
import moment from 'moment';

export default class Job extends BaseModel {
  get formattedViews() {
    return mapValues(this.views, (value) => {
      const len = Numeral(value || 0).format('0a');
      return len + ' view' + (len !== 1 ? 's' : '');
    });
  }

  get startupEndpoint() {
    return this.company.endpoint + '/jobs/' + this.id;
  }

  get deadline() {
    return (
      dayjs(this.expirationDate).format('DD MMM YYYY') +
      ' (' +
      dayjs(this.expirationDate).fromNow() +
      ')'
    );
  }

  get published() {
    const published = dayjs(this.createdAt).fromNow();
    return published !== 'a day ago' ? published : '';
  }

  get url() {
    return '/jobs/' + this.id;
  }

  get absoluteUrl() {
    return process.env.BASE_URL + '/jobs/' + this.id;
  }

  get adminUrl() {
    return '/client/startups/' + this.company.key + '/jobs/' + this.id;
  }

  get editUrl() {
    return '/client/startups/' + this.company.key + '/jobs/' + this.id + '/edit';
  }

  get monetiseUrl() {
    return '/client/startups/' + this.company.key + '/jobs/' + this.id + '/monetise';
  }

  get boostUrl() {
    return '/client/startups/' + this.company.key + '/jobs/' + this.id + '/boost';
  }

  get isActive() {
    return this.status === 'ACTIVE';
  }

  get isPaused() {
    return this.status === 'STOPPED';
  }

  get isExpired() {
    return this.status === 'EXPIRED';
  }

  get isDraft() {
    return this.status === 'DRAFT';
  }

  get isPendingPayment() {
    return this.status === 'PENDING_PAYMENT';
  }

  get isPending() {
    return this.status === 'PENDING_COMPANY_APPROVAL';
  }

  get isApproved() {
    return !!this.approvedAt;
  }

  get isBoosted() {
    return moment(this.boostingExpiresAt) > moment();
  }

  get shareUrl() {
    return this.absoluteUrl;
  }

  get shareSubject() {
    return `Interesting job for you? ${this.title} at ${this.company.name}`;
  }

  get shareBody() {
    return `Hi, I found an interesting job for you \n\n{url}`;
  }

  get previewLocation() {
    if (this.isRemote) {
      return 'Remote';
    }

    if (!this.location) {
      return '';
    }

    if (this.location.locality && this.location.country) {
      return this.location.locality + ', ' + this.location.country;
    }

    if (this.location.locality) {
      return this.location.locality;
    }

    if (this.location.address) {
      return this.location.address.split(',')[0];
    }
    return '';
  }

  checkoutUrl(cancel_url = null) {
    if (!cancel_url) {
      cancel_url = `${process.env.BASE_URL}/client/startups/${this.company.key}/jobs/`;
    }
    // return `/jobs/${this.id}/monetise/:productId`
    return `/api/jobs/${this.id}/checkout?cancel_url=${cancel_url}`;
  }
}

// helper
class Share extends BaseModel {}

Share.mapping = {
  image: Image
};

Job.mapping = {
  share: Share,
  company: Company,
  location: Location,
  board: Board
};
