import { EventBus } from '@/plugins/events';

// initial state
const state = () => ({});

// getters
const getters = {};

// actions
const actions = {
  async authorizeWorkable() {
    return new Promise((resolve, reject) => {
      EventBus.$off('workable:auth');
      EventBus.$once('workable:auth', function(event) {
        clearInterval(detectWindowCloseInterval);

        // console.log(event)

        if (event.payload.error) {
          // user pressed "deny access" (same as "cancel")
          if (event.payload.error === 'access_denied') {
            resolve({
              didAuthorize: false
            });
          } else {
            reject(
              new Error({
                message: event.payload.errorDescription
              })
            );
          }

          return;
        }

        resolve({
          didAuthorize: true,
          data: event.payload
        });
      });

      const url = `https://www.${process.env.WORKABLE_DOMAIN}/oauth/authorize?client_id=${process.env.WORKABLE_CLIENT_ID}&redirect_uri=${process.env.WORKABLE_REDIRECT_URL}&resource=user&response_type=code&scope=r_jobs+w_candidates`;
      const windowHandler = window.open(url, 'workable', 'width=800,height=650,scrollbars=no');

      const detectWindowCloseInterval = setInterval(function() {
        if (windowHandler.closed) {
          clearInterval(detectWindowCloseInterval);

          resolve({
            didAuthorize: false
          });
        }
      }, 250);
    });
  }
};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
