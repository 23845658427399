<template>
  <header id="app-header">
    <b-navbar toggleable="md">
      <b-navbar-brand to="/">
        <!-- <img
          class="hidden-md-down"
          src="@/assets/img/hub-2023.png"
          width="66"
          height="30"
          alt="The Hub logo"
        />
        <img
          class="visible-md-down"
          src="@/assets/img/hub-2023.png"
          width="66"
          height="30"
          alt="The Hub logo"
        /> -->
        <div v-if="shouldBeDB">
          <img
            class="hidden-md-down"
            src="@/assets/img/dbg_logo.png"
            width="210"
            height="30"
            alt="The Hub logo"
          />
          <img
            class="visible-md-down"
            src="@/assets/img/dbg_logo.png"
            width="210"
            height="30"
            alt="The Hub logo"
          />
        </div>
        <div v-else>
          <img
            class="hidden-md-down"
            src="@/assets/img/hub-2023.png"
            width="66"
            height="30"
            alt="The Hub logo"
          />
          <img
            class="visible-md-down"
            src="@/assets/img/hub-2023.png"
            width="66"
            height="30"
            alt="The Hub logo"
          />
        </div>
      </b-navbar-brand>

      <div class="ml-auto"></div>

      <b-collapse id="nav_collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <template v-for="(item, i) in nav">
            <b-nav-text v-if="item.spacer" :key="`spacer-${i}`" class="spacer"></b-nav-text>

            <a
              v-else-if="item.external"
              :key="`ext-link-${i}`"
              class="nav-link ext-link"
              :href="item.link"
              target="_blank"
            >
              <span :title="item.label">{{ item.label }}</span>
              <div v-if="item.chevron" :key="`chevron-${i}`" class="headerbar-chevron">
                {{ item.chevron }}
              </div>
            </a>

            <b-nav-item
              v-else
              :key="item.link"
              :class="$route.path === item.link ? 'menu-active' : ''"
              :to="item.link"
            >
              <span :title="item.label">{{ item.label }}</span>
              <div v-if="item.chevron" :key="`chevron-${i}`" class="headerbar-chevron">
                {{ item.chevron }}
              </div>
            </b-nav-item>
          </template>
        </b-navbar-nav>

        <b-navbar-nav>
          <b-nav-item v-if="!isLoggedIn" @click="onSignUp"
            ><span title="Sign up">Sign up</span>
          </b-nav-item>

          <b-nav-item v-if="!isLoggedIn" @click="onSignIn"
            ><span title="Log in">Log in</span>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>

      <b-navbar-nav v-if="isLoggedIn">
        <UserProfileDropdown />
      </b-navbar-nav>

      <b-navbar-toggle target="nav_collapse" />
    </b-navbar>
    <InfoHeaderBanner></InfoHeaderBanner>
  </header>
</template>

<script>
import UserProfileDropdown from '@/components/user/UserProfileDropdown';
import InfoHeaderBanner from '@/components/InfoHeaderBanner';

import { chatInit } from '../services/chatHelper.js';
export default {
  name: 'AppHeader',
  components: {
    UserProfileDropdown,
    InfoHeaderBanner
  },
  computed: {
    shouldBeDB() {
      // console.log('this.$route.fullPath', this.$route.fullPath)
      // console.log(
      //   "this.$route.fullPath === 'partners/danske-bank-growth'",
      //   this.$route.fullPath === '/partners/danske-bank-growth'
      // )
      //partners/danske-bank-growth
      //fullPath
      return this.$route.fullPath === '/partners/danske-bank-growth';
    },
    nav() {
      const tempNav = this.isLoggedIn ? this.clientNavigationItems : this.defaultNavigationItems;
      return tempNav.filter((obj) => Object.keys(obj).length > 0);
    },
    defaultNavigationItems() {
      const items = [
        {
          link: '/jobs',
          label: 'Find jobs'
        },
        {
          link: '/startups',
          label: 'Browse startups'
        },
        // {
        //   link: 'https://insights.thehub.io',
        //   label: 'News & Insights',
        //   external: true
        // },
        {
          spacer: true
        },
        {
          link: '/pricing',
          label: 'Pricing'
        },
        {
          ...(this.products.includes('HEADHUNTER')
            ? {
                link: '/headhunter',
                label: 'Headhunter',
                chevron: 'NEW'
              }
            : {})
        },
        {
          ...(this.products.includes('MAX')
            ? {
                link:
                  'https://www.notion.so/thehubio/Max-job-post-10334d604eaa80b2b841e8fc24a33f8a',
                label: 'Max',
                external: true,
                chevron: 'NEW'
              }
            : {})
        },
        {},
        {
          link: '/startups/join',
          label: 'For startups'
        },
        {
          spacer: true
        }
      ];
      return items;
    },
    clientNavigationItems() {
      if (!this.userCurrentProfile) {
        return [
          {
            link: '/investors/join',
            label: 'For investors'
          },
          {
            link: '/startups/join',
            label: 'For startups'
          }
        ];
      }

      switch (this.userCurrentProfile.type) {
        case 'company':
          return [
            {
              link: '/jobs',
              label: 'Find jobs'
            },
            {
              link: '/startups',
              label: 'Browse startups'
            },
            // {
            //   link: 'https://insights.thehub.io',
            //   label: 'News & Insights',
            //   external: true
            // },
            {
              spacer: true
            },
            {
              ...(this.products.includes('HEADHUNTER')
                ? {
                    link: '/headhunter',
                    label: 'Headhunter',
                    chevron: 'NEW'
                  }
                : {})
            },
            {
              ...(this.products.includes('MAX')
                ? {
                    link:
                      'https://www.notion.so/thehubio/Max-job-post-10334d604eaa80b2b841e8fc24a33f8a',
                    label: 'Max',
                    external: true,
                    chevron: 'NEW'
                  }
                : {})
            },
            {
              link: '/pricing',
              label: 'Pricing'
            },
            {
              spacer: true
            },
            {
              link: `${this.userCurrentProfile.link}/jobs`,
              label: 'My jobs'
            }
          ];
        case 'investor':
          return [
            {
              link: '/jobs',
              label: 'Find jobs'
            },
            {
              link: '/startups',
              label: 'Browse startups'
            },
            {
              link: 'https://insights.thehub.io',
              label: 'News & Insights',
              external: true
            },
            {
              spacer: true
            },
            {
              link: `/client/followed-startups`,
              label: 'Followed startups'
            }
          ];
        case 'applicant':
          return [
            {
              link: '/jobs',
              label: 'Find jobs'
            },
            {
              link: '/startups',
              label: 'Browse startups'
            },
            {
              link: 'https://insights.thehub.io',
              label: 'News & Insights',
              external: true
            },
            {
              spacer: true
            },
            {
              link: `/client/followed-startups`,
              label: 'Followed startups'
            },
            {
              link: `/client/my-jobs`,
              label: 'My jobs'
            }
          ];

        default:
          return this.defaultNavigationItems;
      }
    },
    isLoggedIn() {
      return this.$store.getters['user/isLoggedIn'];
    },
    userCurrentProfile() {
      return this.$store.getters['user/currentProfile'];
    },
    userHasSelectedProfile() {
      return this.$store.getters['user/hasSelectedProfile'];
    },
    userHasMultipleProfiles() {
      return this.$store.getters['user/hasMultipleProfiles'];
    },
    userHasAnyProfile() {
      return this.$store.getters['user/hasAnyProfile'];
    },
    userHasProfiles() {
      return this.$store.getters['user/hasProfiles'];
    },
    products() {
      return this.$store.getters['meta/products'];
    },
    displayInfoBanner() {
      return true;
    }
    /*     hasUserClosedInfoHeaderBanner() {
      const reachedUiFeatures = this.$store.state.user.data.uiFeatures
      return reachedUiFeatures.some(
        (feature) => feature['name'] === 'maintenanceBanner'
      )
    } */
  },
  mounted() {
    this.$eventBus.$on('login', this.onSignIn);
    this.$eventBus.$on('signup', this.onSignUp);

    if (this.$route.query.to) {
      this.onSignIn();
    }
  },
  beforeDestroy() {
    this.$eventBus.$off('login', this.onSignIn);
    this.$eventBus.$off('signup', this.onSignUp);
  },
  methods: {
    onSignUp() {
      this.$store.dispatch('modal/auth', {
        initialRoute: 'signup-landing',
        onAuthSuccess: this.onAuthSuccess,
        onCancel() {
          this.$store.dispatch('modal/cancel');
        }
      });
    },
    onSignIn() {
      this.$store.dispatch('modal/auth', {
        initialRoute: 'signin',
        onAuthSuccess: this.onAuthSuccess,
        onCancel() {
          this.$store.dispatch('modal/cancel');
        }
      });
    },
    onAuthSuccess() {
      console.log(
        // 'onAuthSuccess !!!!!!!!!!! >>>>>>>>>>>>>>',
        this.$store.getters['user/data']
      );
      chatInit({ user: this.$store.getters['user/data'], isHidden: true });
      this.$store.dispatch('modal/cancel');

      if (!this.userHasProfiles) {
        if (this.userHasAnyProfile) {
          this.$store.commit('modal/connectProfile');
        } else {
          this.$store.commit('modal/chooseRole');
        }
      } else if (this.userHasSelectedProfile) {
        this.$router.push(
          this.$route.query.to ? this.$route.query.to : this.userCurrentProfile.link
        );
      } else if (this.userHasMultipleProfiles) {
        this.$store.commit('modal/switchProfile');
      }
    },
    async signOut() {
      try {
        await this.$store.dispatch('user/logout');
        this.$router.push('/');
      } catch (err) {
        console.error(err);
      }
    }
  }
};
</script>

<style lang="scss">
.show {
  .nav-link {
    height: 3.3rem !important;
  }
}

.nav-link {
  @include media-breakpoint-down(sm) {
    height: 3.3rem !important;
  }
}

.headerbar-chevron {
  background-color: #4440db;
  font-size: rem(12px);
  color: white;
  font-weight: 700;
  // padding: 0.25rem 1rem;
  height: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.65rem;
  padding-right: 0.65rem;
  margin-left: 0.5rem;
  padding-top: 0.1rem;
  margin-bottom: 0.1rem;
  user-select: none;
}
#app-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  .navbar {
    height: $app-header-height;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 rem(20px) 0 rem(30px);

    @include media-breakpoint-down(lg) {
      padding: 0 rem(10px) 0 rem(20px);
    }

    @include media-breakpoint-down(sm) {
      padding: 0 rem(20px);
    }

    .ext-link {
      justify-content: center;
    }

    .navbar-nav {
      align-self: flex-start;
      position: relative;
      z-index: 12;
    }

    .navbar-brand {
      margin-right: 0;
      z-index: 10;
      display: flex;
      align-items: flex-end;

      img {
        margin-right: rem(10px);
      }

      span {
        color: #949393;
        font-size: rem(12px);
        line-height: rem(12px);
      }
    }

    .navbar-toggler {
      background: transparent;
      border: none;
      outline: none !important;
      padding: 10px;
      cursor: pointer;
      z-index: 10;

      span {
        position: relative;
        display: block;
        width: 24px;
        height: 23px;
        background-image: none;

        &:before,
        &:after {
          display: block;
          content: '';
          width: 100%;
          height: 3px;
          background-color: $gray-900;
          position: absolute;
          top: 4px;
          left: 0;
          right: 0;
          @include transition(0.3s, all, ease-out, 0);
        }

        &:after {
          top: auto;
          left: 0;
          right: 0;
          bottom: 4px;
          @include transition(0.33s, all, ease-in, 0);
        }
      }

      &[aria-expanded='true'] {
        span {
          &:before {
            transform: translateY(6px) rotate(225deg);
          }

          &:after {
            transform: translateY(-6px) rotate(-225deg);
          }
        }
      }
    }

    .navbar-collapse {
      @include media-breakpoint-down(sm) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 9;
        background: $gray-100;
        box-shadow: $box-shadow-light;

        .navbar-nav {
          &:first-child {
            padding-top: $app-header-height;
          }

          &:last-child {
            padding-bottom: $app-header-height/2;
          }
        }

        .nav-item {
          text-align: center;

          .nav-link {
            height: $app-header-height * 0.66;
          }
        }
      }
    }

    // .nav-link {
    //   height: $app-header-height * 0.66;
    // }
    .nav-link,
    .navbar-text {
      display: inline-flex;
      align-items: center;
      height: $app-header-height;
      font-size: rem(15px);
      padding: 0 rem(13px);
      margin: 0;
      line-height: 1;
      border-top: 2px solid transparent;
      border-bottom: 2px solid transparent;
      color: $gray-900;
      font-weight: 400;
      text-align: center;
      transition: color 0.3s, font-weight 0.3s;

      @include media-breakpoint-down(sm) {
        font-size: rem(18px);
      }

      span {
        position: relative;
      }

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
        color: $blue-900;
      }
    }

    .menu-active {
      .nav-link {
        border-bottom-color: $blue-900 !important;

        span::after {
          font-weight: 700;
          color: $blue-900;
        }
      }
    }

    .navbar-text.spacer {
      padding: 0;
      margin: 0 rem(12px);
      height: rem(30px);
      margin-top: calc((#{$app-header-height} - 30px) / 2);
      border-left: 1px solid rgba($gray-900, 0.2);

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .dropdown-toggle {
      i {
        font-size: rem(10px);
        color: $blue-900;
        margin-left: rem(5px);
      }
    }
  }

  @media (max-width: 1080px) {
    .navbar {
      .nav-link,
      .navbar-text {
        padding: 0 rem(7px);
      }

      .navbar-text.spacer {
        margin-left: rem(7px);
        margin-right: rem(7px);
      }
    }
  }
}
</style>
