import Vue from 'vue'
import VueWaypoint from 'vue-waypoint'
import { EventBus } from '@/plugins/events'

Vue.use(VueWaypoint)

/**
 * pass `postMessage(...)` via our event-bus.
 */
window.addEventListener('message', function(event) {
  if (event.data.name) {
    EventBus.$emit(event.data.name, event.data)
  }
})
