<template>
  <div class="layout-base">
    <div class="layout-base__body">
      <slot />
    </div>

    <div class="layout-base__modals">
      <AuthFlow
        v-if="modalAuthFlow.isVisible"
        :initial-route="modalAuthFlow.initialRoute"
        :disable-sign-up="modalAuthFlow.disableSignUp"
        :on-cancel="modalAuthFlow.onCancelCallback"
        :on-auth-success="modalAuthFlow.onAuthSuccessCallback"
        :defaults="modalAuthFlow.defaults"
      />

      <SignSwitchProfileModal v-model="modalSwitchProfile" />

      <SignChooseRoleModal v-model="modalChooseRole" />

      <SignConnectProfileModal v-model="modalConnectProfile" />

      <!-- <DetectVersion /> -->
    </div>
  </div>
</template>

<script>
import AuthFlow from '@/components/auth-flow/index'
import SignSwitchProfileModal from '@/modals/sign/SignSwitchProfile'
import SignChooseRoleModal from '@/modals/sign/SignChooseRole'
import SignConnectProfileModal from '@/modals/sign/SignConnectProfile'

// import DetectVersion from '@/components/DetectVersion'

export default {
  name: 'BaseLayout',
  components: {
    AuthFlow,
    SignSwitchProfileModal,
    SignChooseRoleModal,
    SignConnectProfileModal
  },
  // mounted() {
  //   if (process.env.DISABLE_INDEXING) alert(process.env.DISABLE_INDEXING)
  // },
  head() {
    const disableIndexing = process.env.DISABLE_INDEXING === 'true'
    return disableIndexing
      ? {
          meta: [
            { hid: 'robots', name: 'robots', content: 'noindex, nofollow' }
          ]
        }
      : {}
  },
  // metaInfo: {
  //   meta: [{ name: 'robots', content: 'noindex, nofollow' }]
  // },
  computed: {
    modalAuthFlow() {
      return this.$store.getters['modal/authFlow']
    },
    modalSwitchProfile: {
      get() {
        return this.$store.state.modal.switchProfile
      },
      set(value) {
        this.$store.commit('modal/switchProfile', value)
      }
    },
    modalChooseRole: {
      get() {
        return this.$store.state.modal.chooseRole
      },
      set(value) {
        this.$store.commit('modal/chooseRole', value)
      }
    },
    modalConnectProfile: {
      get() {
        return this.$store.state.modal.connectProfile
      },
      set(value) {
        this.$store.commit('modal/connectProfile', value)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.Cookie.Cookie--hub {
  z-index: 1050;
  left: rem(20px);
  right: auto;
  bottom: rem(20px);
  width: auto;
  background: #fff;
  box-shadow: 0 rem(2px) rem(4px) transparentize(#000, 0.5);
  padding: rem(30px) rem(30px) rem(20px);

  @include media-breakpoint-down(xs) {
    width: 100%;
    left: 0;
    right: auto;
    bottom: 0;
  }

  > * {
    margin: 0;
  }

  p,
  a {
    color: $gray-700;
    font-size: rem(14px);
    line-height: rem(20px);
  }

  p {
    max-width: rem(260px);

    @include media-breakpoint-down(xs) {
      max-width: none;
    }
  }

  a {
    text-decoration: underline;

    &:hover {
      color: $blue-800;
    }
  }

  .btn {
    width: 100%;
  }
}
</style>
