<template>
  <b-modal
    id="userSignIn"
    v-model="modal"
    centered
    title="Log in"
    :hide-footer="hideSignUpButton"
    modal-class="modal-bb-squared"
    footer-class="light-blue"
    v-on="$listeners"
  >
    <template slot="default">
      <div>
        <button
          class="btn btn-lg btn-linkedin w-100"
          @click="onAuthorizeWithLinkedin"
        >
          <span v-if="!isAuthorizingLinkedin"
            ><i class="icon-linkedin"></i
            ><span>Log in with LinkedIn</span></span
          >
          <b-spinner v-else small />
        </button>
      </div>

      <form @submit.prevent="onSubmit">
        <div class="spacer-text"><span>or</span></div>

        <AlertSlideUpDown :show="promptInvalidLoginError"
          >Invalid email or password</AlertSlideUpDown
        >

        <FormItem
          invalid-message="Please enter your email address"
          :state="$v.form.email.$dirty ? !$v.form.email.$error : null"
          required
        >
          <b-form-input
            v-model="form.email"
            :state="$v.form.email.$dirty ? !$v.form.email.$error : null"
            placeholder="Email"
          />
        </FormItem>

        <FormItem
          invalid-message="Please enter your password"
          :state="$v.form.password.$dirty ? !$v.form.password.$error : null"
          required
        >
          <b-form-input
            v-model="form.password"
            :state="$v.form.password.$dirty ? !$v.form.password.$error : null"
            type="password"
            placeholder="Password"
          />
        </FormItem>

        <div class="text-right pb-20">
          <a class="text-blue-900 fs14 cp" @click="onGotoForgotPassword">
            Forgot password?
          </a>
        </div>
        <button class="btn btn-lg w-100 mb-16" type="submit">
          <span v-if="!isRequestingSignIn">Log in</span>
          <b-spinner v-else small />
        </button>
      </form>
    </template>
    <template slot="modal-footer">
      <div>
        <span>Don't have an account? </span>
        <button class="btn btn-link fs14" @click="onGotoSignUp">
          Sign up
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import FormItem from '@/components/form/FormItem/index'
import AlertSlideUpDown from '@/components/AlertSlideUpDown'

export default {
  name: 'UserSignInModal',
  components: {
    FormItem,
    AlertSlideUpDown
  },
  mixins: [validationMixin],
  props: {
    isAuthorizingLinkedin: Boolean,
    onAuthorizeWithLinkedin: {
      type: Function,
      required: true
    },
    onGotoForgotPassword: {
      type: Function,
      required: true
    },
    onGotoSignUp: {
      type: Function,
      required: true
    },
    hideSignUpButton: {
      type: Boolean,
      default: false
    },
    defaults: {
      type: Object
    }
  },
  data: () => ({
    isRequestingSignIn: false,
    modal: true,
    promptInvalidLoginError: false,
    form: {
      email: '',
      password: ''
    }
  }),
  mounted() {
    this.form.email = this.defaults.email || ''
  },
  methods: {
    async onSubmit() {
      if (this.isRequestingSignIn || this.isAuthorizingLinkedin) {
        return
      }

      this.$v.form.$touch()

      if (this.$v.form.$error) {
        return
      }

      this.isRequestingSignIn = true

      try {
        await this.$store.dispatch('user/login', {
          ...this.form
        })
      } catch (err) {
        this.promptInvalidLoginError = true
      }

      this.isRequestingSignIn = false
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required
      }
    }
  }
}
</script>
