import BaseModel from './BaseModel'
import File from './File'
import Image from './Image'

export default class Tool extends BaseModel {
  get url() {
    return '/deals/' + this.key
  }

  get absoluteUrl() {
    return process.env.BASE_URL + '/deals/' + this.key
  }

  get isExtended() {
    return this.type === 'EXTENDED'
  }

  get isDownloadable() {
    return this.type === 'DOWNLOAD'
  }
}

Tool.mapping = {
  image: Image,
  partnerLogo: Image,
  documentFile: File
}
