<template>
  <div>
    <div v-if="isAuthError">
      <b-container
        class="error-page d-flex justify-content-center align-items-center"
      >
        <p class="error-page__description">
          Session expired, redirecting you to frontpage
        </p>
      </b-container>
    </div>
    <div v-else>
      <b-container class="error-page">
        <h2 class="error-page__title">{{ title }}</h2>
        <p v-if="error.message" class="error-page__description">
          {{ error.message }}
        </p>

        <a href="/" class="btn btn-lg">Back to home</a>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  computed: {
    title() {
      return this.error.statusCode === 404
        ? 'Page not found'
        : 'Something went wrong'
    },
    isAuthError() {
      return this.error.statusCode === 403
    }
  }
}
</script>

<style lang="scss" scoped>
.error-page {
  padding: 2rem 0;
  min-height: 35rem;
  text-align: center;
  &__title {
    margin-top: 3rem;
  }

  &__description {
    color: $gray-700;
    margin-top: 0.5rem;
    margin-bottom: 3rem;
  }
}
</style>
