<template>
  <b-modal
    id="userChooseRole"
    v-model="model"
    centered
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    modal-class="modal-bb-squared modal-choose-role"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <div class="choose-role-header">
        <h5 class="pb-10">{{ title }}</h5>
      </div>
      <button class="close" @click="close()"></button>
    </template>
    <template slot="default">
      <b-row class="justify-content-center">
        <b-col v-for="(role, i) in roles" :key="i" cols="12" md="auto">
          <div class="role" @click="select(role)">
            <div class="icon"><img :src="role.icon" alt="" /></div>
            <div class="label">{{ role.label }}</div>
          </div>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'SignChooseRoleModal',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      default: 'Let us know who you are'
    },
    type: {
      type: String,
      default: 'signup'
    }
  },
  computed: {
    roles() {
      if (this.type === 'signup') {
        return [
          {
            link: '/client/applicant?create=1',
            icon: require('@/assets/img/roles/talent.svg'),
            label: "I'm looking for jobs"
          },
          {
            link: '/startups/join',
            icon: require('@/assets/img/roles/startup.svg'),
            label: "I'm a startup"
          },
          {
            link: '/investors/join',
            icon: require('@/assets/img/roles/investor.svg'),
            label: "I'm an investor"
          }
        ]
      } else {
        return [
          {
            link: '/startups/join',
            icon: require('@/assets/img/roles/startup.svg'),
            label: 'Startup'
          },
          {
            link: '/investors/join',
            icon: require('@/assets/img/roles/investor.svg'),
            label: 'Investor'
          }
        ]
      }
    },
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    profiles() {
      return this.$store.getters['user/profiles']
    }
  },
  methods: {
    async select(role) {
      this.$router.push(role.link)

      this.model = false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .modal-choose-role {
  .modal-dialog {
    max-width: 800px;
  }
}

.choose-role-header {
  text-align: center;
}

.role {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  min-width: 180px;
  margin: 10px 0;
  height: 140px;
  text-align: center;
  border: 1px solid $border-color;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: $gray-100;
  }

  .icon {
    margin-bottom: rem(16px);
  }

  .type {
    color: $gray-700;
    font-size: rem(14px);
  }
}
</style>
