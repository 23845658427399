import dayjs from 'dayjs'
import orderBy from 'lodash/orderBy'
import get from 'lodash/get'
import find from 'lodash/find'
const storageKey = 'search-history'
const ttl = 60 * 60 * 24 * 30

const getKeyByFilters = function(filters) {
  let key = {}

  if (filters.search) {
    key.search = filters.search
  }

  if (filters.location) {
    key.location = filters.location
  }

  if (filters.countryCode) {
    key.countryCode = filters.countryCode
  }

  return JSON.stringify(key)
}

// initial state
const state = () => ({
  list: [],
  loaded: false
})

// getters
const getters = {}

// actions
const actions = {
  async add({ state, dispatch, commit }, { params, createIfNotExists }) {
    if (!params.search && !params.location) {
      return
    }

    if (!state.loaded) {
      await dispatch('load')
    }

    const key = getKeyByFilters(params)

    const existing = state.list.find((item) => item.key === key)

    if (existing) {
      // remove existing because we will add a updated version
      commit('remove', existing)
    } else if (!createIfNotExists) {
      return
    }

    const item = {
      id: existing ? existing.id : null,
      key,
      filters: params,
      pinned: existing ? existing.pinned : false,
      since: dayjs.utc().toISOString()
    }

    commit('add', item)

    // update pinned search
    if (item.id) {
      await this.$axios.patch('job-search/' + item.id, {
        key: JSON.parse(item.key),
        filters: item.filters,
        since: item.since
      })
    }

    dispatch('save')
  },
  async load({ commit, rootState }) {
    const localData = window.localStorage.getItem(storageKey)

    const now = dayjs.utc()
    let items = JSON.parse(localData || '[]').filter((item) =>
      dayjs
        .utc(item.since)
        .add(ttl, 'seconds')
        .isAfter(now)
    )

    items = orderBy(items, ['since'], ['desc'])

    if (rootState.user.data) {
      const { data } = await this.$axios.get('job-search')

      items.splice(
        0,
        0,
        ...orderBy(
          data.map((item) => {
            // remap order
            item.key = getKeyByFilters(item.filters)

            return item
          }),
          ['since'],
          ['desc']
        )
      )

      items = items.filter((value, index, self) => {
        return self.findIndex((item) => item.key === value.key) === index
      })
    }

    items = items.map((item) => {
      item.pinned = !!item.id

      return item
    })

    commit('setList', items)
  },
  async loadAndCount({ state, commit, dispatch }) {
    await dispatch('load')

    const { data } = await this.$axios.post('jobs/count/bulk', {
      items: state.list.map((item) => ({
        key: item.key,
        ...item.filters,
        since: item.since
      }))
    })

    commit('setCounts', data)
  },
  async togglePin({ commit, dispatch }, item) {
    const pin = !item.pinned

    commit('setPinned', {
      item,
      value: pin
    })

    if (pin) {
      const { data } = await this.$axios.post('job-search', {
        key: JSON.parse(item.key),
        filters: item.filters,
        since: item.since
      })

      commit('setId', {
        item,
        id: data.id
      })
    } else {
      try {
        await this.$axios.delete('job-search/' + item.id)
      } catch (err) {
        // silent
      }

      commit('setId', {
        item,
        id: null
      })
    }

    dispatch('save')
  },
  save({ state }) {
    window.localStorage.setItem(
      storageKey,
      JSON.stringify(
        state.list.map((item) => ({
          key: item.key,
          filters: item.filters,
          since: item.since
        }))
      )
    )
  }
}

// mutations
const mutations = {
  setList(state, value) {
    state.list = value
    state.loaded = true
  },
  remove(state, item) {
    state.list.splice(state.list.indexOf(item), 1)
  },
  add(state, item) {
    state.list.unshift(item)
  },
  setCounts(state, data) {
    state.list = state.list.map((item) => {
      const result = find(
        data.result,
        (countItem) => item.key === get(countItem, 'filters.key')
      )

      return {
        ...item,
        count: result ? result.count : 0
      }
    })
  },
  setPinned(state, { item, value }) {
    item.pinned = value
  },
  setId(state, { item, id }) {
    item.id = id
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
