export const chatLoad = ({ user, isHidden }) => {
  // console.log('chatHelper > chatLoad >>>>>>>>>>>>>')
  return {
    hid: 'FreshdeskChat',
    src: 'https://thehubio-support.freshchat.com/js/widget.js',
    chat: true,
    async: true,
    callback: () => {
      // console.log('chatHelper > chatLoad > callback >>>>>>>>>>>>>')
      chatInit({ user, isHidden })
    }
  }
}

export const chatInit = ({ user, isHidden }) => {
  // console.log(
  //   'chatHelper > chatInit >>>>>>>>>>>>> user, isHidden',
  //   user,
  //   isHidden
  // )
  if (window.fcWidget && !window.fcWidget.isInitialized()) {
    window.fcWidget.init({
      // open: true,
      config: {
        headerProperty: {
          hideChatButton: isHidden
        }
      },
      token: '3b4133d7-7131-4a78-bd40-f65273d75329',
      host: 'https://thehubio-support.freshchat.com',
      externalId: user?.email,
      firstName: user?.name?.first,
      lastName: user?.name?.last,
      email: user?.email,
      onLoad: () => {
        const company =
          user?.companies?.map((company) => company.name)?.join('/') || null
        company && window.fcWidget?.user?.setMeta({ company })
      }
    })
  } else {
    if (window.fcWidget && window.fcWidget.isInitialized() && user) {
      window?.fcWidget?.setExternalId(user?.email)
      window?.fcWidget?.user?.setEmail(user?.email)
      window?.fcWidget?.user?.setFirstName(user?.name?.first)
      window?.fcWidget?.user?.setLastName(user?.name?.last)
    }
  }
}

export const chatCrutch = () => {
  return
}

export const chatDestroy = () => {
  var removalTimer = setInterval(() => {
    if (window.fcWidget) {
      window.fcWidget.destroy()
    } else {
      clearInterval(removalTimer)
    }
  }, 1)
}

export const chatHide = () => {
  // console.log('chatHelper > chatHide >>>>>>>>>>>>>', window.fcWidget)
  var timer = setInterval(() => {
    const chat = document.getElementById('fc_frame')
    if (chat) {
      // console.log('chatHelper > chatHide >>>>>>>>>>>>> chat.offsetWidth', chat.offsetWidth)
      if (chat.offsetWidth != undefined) {
        window.fcWidget &&
          window.fcWidget.setConfig({
            headerProperty: {
              hideChatButton: true
            }
          })

        clearInterval(timer)
      }
    }
  }, 1)
}

export const chatShow = ({ user }) => {
  // console.log('chatHelper > chatShow >>>>>>>>>>>>> user', user)
  if (window.fcWidget && window.fcWidget.isInitialized() && user) {
    window?.fcWidget?.setExternalId(user?.email)
    window?.fcWidget?.user?.setEmail(user?.email)
    window?.fcWidget?.user?.setFirstName(user?.name?.first)
    window?.fcWidget?.user?.setLastName(user?.name?.last)
  }
  var timer = setInterval(() => {
    const chat = document.getElementById('fc_frame')
    // console.log('chatHelper > chatShow >>>>>>>>>>>>> chat', chat)
    if (chat) {
      // console.log('chatHelper > chatShow >>>>>>>>>>>>> chat.offsetWidth', chat.offsetWidth)
      if (chat.offsetWidth == 0) {
        window.fcWidget &&
          window.fcWidget.setConfig({
            headerProperty: {
              hideChatButton: false
            }
          })
        clearInterval(timer)
      }
    }
  }, 1)
}
