import BaseModel from './BaseModel';
import CompanyTeamItem from './CompanyTeamItem';
import CompanyInvestor from './CompanyInvestor';
import Investor from './Investor';
import CompanyQuote from './CompanyQuote';
import CompanyCountry from './CompanyCountry';
import Image from '@/models/Image';
import Numeral from 'numeral';
import { mapValues } from 'lodash';

export default class Company extends BaseModel {
  get endpoint() {
    return '/companies/' + this.key;
  }

  get url() {
    return '/startups/' + this.key;
  }

  get absoluteUrl() {
    return process.env.BASE_URL + '/startups/' + this.key;
  }

  get adminUrl() {
    return '/client/startups/' + this.key;
  }

  get websiteUrl() {
    return this.website && this.website.match(/^https?:/) ? this.website : 'http://' + this.website;
  }

  get logoImageUrl() {
    return this.logoImage ? this.logoImage.thumbnailSizeUrl : null;
  }

  get formattedJobCount() {
    const len = this.numberOfActiveJobs;

    return len + ' job' + (len !== 1 ? 's' : '');
  }

  get formattedViews() {
    return mapValues(this.views, (value) => {
      const len = Numeral(value || 0).format('0a');
      return len + ' view' + (len !== 1 ? 's' : '');
    });
  }

  get description() {
    return this.whatWeDo || '';
  }
}

Company.mapping = {
  logoImage: Image,
  galleryImages: Image,
  team: CompanyTeamItem,
  investors: CompanyInvestor,
  quote: CompanyQuote,
  countries: CompanyCountry
};

Investor.mapping.company = Company;
