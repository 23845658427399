import InvestorModel from '@/models/Investor'

// initial state
const state = () => ({
  investors: null,
  investor: null
})

// getters
const getters = {}

// actions
const actions = {
  async find({ commit }, params) {
    const { data } = await this.$axios.get('/investors', {
      params
    })

    commit('setInvestors', {
      ...data,
      docs: data.docs.map((item) => new InvestorModel(item))
    })
  },

  async get({ commit }, id) {
    const { data } = await this.$axios.get('investors/' + id)

    commit('setInvestor', new InvestorModel(data))
  }
}

// mutations
const mutations = {
  hydrate(state) {
    if (state.investors) {
      state.investors.docs = state.investors.docs.map(
        (item) => new InvestorModel(item)
      )
    }

    if (state.investor) {
      state.investor = new InvestorModel(state.investor)
    }
  },
  setInvestors(state, data) {
    state.investors = data
  },
  setInvestor(state, data) {
    state.investor = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
