import find from 'lodash/find'

/**
 * if requested company (ex: /client/:company/applicants) is still in DRAFT,
 * redirect the user to the form, to complete the profile.
 */
export default function({ store, params, redirect }) {
  const user = store.getters['user/data']
  if (!user) {
    return
  }

  const company = find(user.companies, { key: params.company })
  if (company && company.status === 'DRAFT') {
    redirect(`/client/startups/${company.key}/edit`)
  }
}
