import { cloneDeep } from 'lodash'
export default {
  methods: {
    async reachUiFeature(name) {
      let uiFeatures = cloneDeep(this.$store.state.user.data.uiFeatures)
      let index = uiFeatures.findIndex((el) => el.name == name)

      if (index == -1) {
        uiFeatures.push({
          name,
          reachedAt: new Date()
        })
      } else {
        uiFeatures[index].reachedAt = new Date()
      }

      const { data } = await this.$axios.put('users/me', {
        uiFeatures
      })

      this.$store.commit('user/update', data)
    }
  }
}
