/**
 * Simple checker of password.
 */
export default function(password) {
  let checks = {
    hasUpperCase: false,
    hasNumber: false,
    hasEightChars: false,
    hasSpecialChars: false
  }

  if (!password) {
    return checks
  }

  if (password.match(/([0-9])/)) {
    checks.hasNumber = true
  }

  // Remember to escape regex characters
  /* eslint-disable no-useless-escape */
  if (
    password.match(
      /([\",!,#,\$,§,%,&,\',\(,\),\*,\+,\,,-,\\,\.,\/,:,;,<,=,>,\?,@,\[,\],\^,_,`,\{,\|,\},~])/
    )
  ) {
    checks.hasSpecialChars = true
  }
  /* eslint-enable no-useless-escape */

  if (password.match(/([A-Z])/)) {
    checks.hasUpperCase = true
  }

  if (password.length >= 8) {
    checks.hasEightChars = true
  }

  return checks
}
