import JobModel from '@/models/Job';
// import { transform as transformJob } from '@/transformers/job';

// initial state
const state = () => ({
  jobs: null,
  job: null,
  latestJobs: null,
  featuredJobs: null,
  jobsWithParams: [],
  savedJobs: [],
  relatedJobs: [],
  relevantJobs: [],
  latestSearchedJobs: [],
  jobById: {}
});

// getters
const getters = {
  jobs: (state) => state.jobs,
  jobsWithParams: (state) => state.jobsWithParams,
  latestJobs: (state) => state.latestJobs,
  featuredJobs: (state) => state.featuredJobs,
  savedJobs: (state) => state.savedJobs,
  relatedJobs: (state) => state.relatedJobs,
  jobById: (state) => state.jobById
};

// actions
const actions = {
  async find({ commit }, params) {
    // Handle remote countryCode and remote jobs
    if (params.countryCode === 'REMOTE') {
      delete params.countryCode;
      params.isRemote = true;
    }
    // console.log('params', params);

    const { data } = await this.$axios.get('/v2/jobs', {
      params
    });

    commit('setJobs', {
      ...data,
      docs: data.docs.map((item) => new JobModel(item))
    });
  },

  // eslint-disable-next-line no-unused-vars
  async findByCompany({ commit }, { companyEndpoint, params }) {
    const uri = companyEndpoint + '/jobs';
    const { data } = await this.$axios.get(uri, {
      params
    });

    return data;
  },
  // eslint-disable-next-line no-unused-vars
  async updateSubscription({ commit }, body) {
    // Handle remote countryCode and add flag for remote jobs
    const includeRemoteJobs = body.locations.some((location) => location.countryCode === 'REMOTE');
    if (includeRemoteJobs) {
      delete body.locations;
      body.includeRemoteJobs = true;
    }

    body.mergeData = true; // merge data instead of overwrite

    // console.log('updateSubscription, body: ', body);

    try {
      return await this.$axios.put('/digest/' + body.settingsKey, body);
    } catch (e) {
      console.log('error', e);
      return e;
    }
  },

  // eslint-disable-next-line no-unused-vars
  async subscribe({ commit }, body) {
    // Handle remote countryCode and add flag for remote jobs
    const includeRemoteJobs = body.locations.some((location) => location.countryCode === 'REMOTE');
    if (includeRemoteJobs) {
      delete body.locations;
      body.includeRemoteJobs = true;
    }

    // console.log('subscribe', body);

    try {
      return await this.$axios.post('/digest', body);
    } catch (e) {
      console.log('error', e);
      return e;
    }
  },

  async getLatestJobs({ commit }) {
    const { data } = await this.$axios.get('/v2/jobs/latest/3');

    commit('setLatestJobs', {
      docs: data.map((item) => new JobModel(item))
    });
  },

  async getFeaturedJobs({ commit }, params) {
    if (params.page) {
      delete params.page;
      // TODO: Implement pagination for featured jobs when the volume becomes big enough
    }

    // Handle remote countryCode and remote jobs
    if (params.countryCode === 'REMOTE') {
      delete params.countryCode;
      params.isRemote = true;
    }

    const { data } = await this.$axios.get('/v2/featuredjobs', {
      params
    });

    commit('setFeaturedJobs', {
      ...data,
      docs: data.docs.map((item) => new JobModel(item)).reverse() //reverse too avoid job repeating in UI
    });
  },

  async getJobsAndFeatured({ commit }, params) {
    // Handle remote countryCode and remote jobs
    if (params.countryCode === 'REMOTE') {
      delete params.countryCode;
      params.isRemote = true;
    }

    // console.log('params', params)

    const { data } = await this.$axios.get('/v2/jobsandfeatured', {
      params
    });

    // console.log('getJobsAndFeatured', data)
    commit('setJobs', {
      ...data.jobs,
      docs: data.jobs.docs.map((item) => new JobModel(item))
    });

    commit('setFeaturedJobs', {
      ...data.featuredJobs,
      docs: data.featuredJobs.docs.map((item) => new JobModel(item)).reverse() //reverse too avoid job repeating in UI
    });
  },

  async get({ commit }, id) {
    const { data } = await this.$axios.get(`/jobs/single/${id}`);
    commit('setJob', new JobModel(data));
  },

  async getHidden({ commit }, id) {
    const { data } = await this.$axios.get(`/jobs/single/${id}/hidden`);
    commit('setJob', new JobModel(data));
  },

  async getRelated({ commit }, id) {
    const { data } = await this.$axios.get(`/v2/jobs/${id}/related`);
    commit('setRelatedJobs', data.map((item) => new JobModel(item)));
  },

  async interested({ commit }, { job, value }) {
    await this.$axios.post('/jobs/' + job.id + (value ? '/interested' : '/uninterested'));
    commit('setJobInterested', { job, value });
  },

  async save({ commit }, { job, value }) {
    await this.$axios.post('/jobs/' + job.id + '/' + (value ? 'save' : 'unsave'));

    commit('setJobSaved', {
      job,
      value
    });
  }
};

// mutations
const mutations = {
  hydrate(state) {
    if (state.jobs) {
      state.jobs.docs = state.jobs.docs.map((item) => new JobModel(item));
    }

    if (state.featuredJobs) {
      // console.log('hydrate!');
      state.featuredJobs.docs = state.featuredJobs.docs.map((item) => new JobModel(item));
    }

    if (state.latestJobs) {
      state.latestJobs.docs = state.latestJobs.docs.map((item) => new JobModel(item));
    }

    if (state.job) {
      state.job = new JobModel(state.job);
    }

    if (state.relatedJobs) {
      state.relatedJobs = state.relatedJobs.map((item) => new JobModel(item));
    }
  },
  setJobs(state, data) {
    state.jobs = data;
  },
  setLatestJobs(state, data) {
    state.latestJobs = data;
  },
  setJob(state, data) {
    state.job = data;
  },
  setRelatedJobs(state, data) {
    state.relatedJobs = data;
  },
  setJobInterested(state, { job, value }) {
    job.interestedAt = value ? new Date().toISOString() : null;
  },
  setJobSaved(state, { job, value }) {
    job.saved = value;
  },
  setFeaturedJobs(state, data) {
    state.featuredJobs = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
