<template>
  <BaseLayout>
    <div class="default-layout">
      <div class="default-layout__body">
        <slot>
          <content>
            <nuxt class="default-layout__body__content" />
          </content>
        </slot>
      </div>

      <slot name="footer">
        <CareersFooterbar />
      </slot>
    </div>
  </BaseLayout>
</template>

<script>
import CareersFooterbar from '@/components/CareersFooterbar'
import BaseLayout from '@/layouts/base'

export default {
  name: 'DefaultLayout',
  components: {
    BaseLayout,
    CareersFooterbar
  }
}
</script>

<style lang="scss">
.default-layout {
  &__body {
    display: block;

    &__content {
      padding-top: $app-header-height;
      min-height: calc(100vh - 414px);

      @include media-breakpoint-down(sm) {
        min-height: calc(100vh - 414px);
      }
    }
  }
}
</style>
