<template>
  <div class="form-item">
    <label v-if="label" class="form-item__label">
      {{ label }}
      <span v-if="required" class="form-item__label__required">*</span>
    </label>

    <div v-if="hasModal" class="form-item__modal">
      <p v-if="modal">{{ modal }}</p>
      <slot v-else name="modal"></slot>
    </div>

    <div class="form-item__body">
      <slot />
    </div>

    <div v-if="hasDescription" class="form-item__description">
      <p v-if="description">{{ description }}</p>
      <slot v-else name="description"></slot>
    </div>

    <SlideUpDown class="form-item__error" :duration="125" :active="showError">
      <div class="form-item__error__inner">{{ invalidMessage }}</div>
    </SlideUpDown>

    <div v-if="note" class="form-item__note">{{ note }}</div>
  </div>
</template>

<script>
import SlideUpDown from 'vue-slide-up-down'

export default {
  name: 'FormItem',
  components: {
    SlideUpDown
  },
  props: {
    required: Boolean,
    label: String,
    description: String,
    note: String,
    invalidMessage: String,
    modal: String,
    /**
     * expects same inputs as 'b-form-input.state' prop.
     */
    state: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      showError: false
    }
  },
  computed: {
    hasDescription() {
      return this.hasDescriptionSlot || this.description
    },
    hasDescriptionSlot() {
      return !!this.$slots.description
    },
    hasModal() {
      return this.hasModalSlot || this.modal
    },
    hasModalSlot() {
      return !!this.$slots.modal
    }
  },
  watch: {
    state(newValue) {
      // delay the expand / collapse of the error message with one "tick"
      // this is needed because "vue-slide-up-down" needs to calculate the height of the new content.
      this.$nextTick(() => {
        this.showError = newValue === false
      })
    }
  },
  mounted() {
    if (this.state === false) {
      this.$nextTick(() => {
        this.showError = true
      })
    }
  }
}
</script>

<style scoped lang="scss">
.form-item {
  margin-bottom: 1.75rem;

  &__label {
    margin: 0 0 0.6rem;
    display: block;
    font-size: rem(16px);

    &__required {
      color: $gray-900;
    }
  }

  &__description {
    font-size: 0.85rem;
    margin-top: 0.5rem;
  }

  &__note {
    margin-top: 0.5rem;
    font-size: 0.85rem;
    text-align: right;
  }

  &__error {
    font-size: 0.85rem;
    color: $red;
    text-align: left;
    opacity: 0.9;

    &__inner {
      padding-top: 0.5rem;
    }
  }
}
</style>
