import Vue from 'vue'

export const EventBus = new Vue()

const events = {
  beforeCreate() {
    this.$eventBus = EventBus
  },
  destroyed() {
    this.$eventBus = null
  }
}

Vue.mixin(events)
