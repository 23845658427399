import Vue from 'vue'

export default class BaseModel {
  constructor(data) {
    this.update(data)
  }

  update(data, vue) {
    const mapping = this.constructor.Mapping || this.constructor.mapping || {}

    for (const name in data) {
      let value

      if (data[name] && mapping[name]) {
        if (Array.isArray(data[name])) {
          value = data[name].map((item) => new mapping[name](item))
        } else {
          value = new mapping[name](data[name])
        }
      } else {
        value = data[name]
      }

      if (vue) {
        Vue.set(this, name, value)
      } else {
        this[name] = value
      }
    }
  }

  getValues(fields) {
    const obj = {}

    let i = fields.length
    while (i--) {
      obj[fields[i]] = this[fields[i]]
    }

    return obj
  }

  set(name, value, reactive = true) {
    const oldValue = this[name]

    if (reactive) {
      if (Object.prototype.hasOwnProperty.call(this, name)) {
        this[name] = value
      } else {
        Vue.set(this, name, value)
      }
    } else if (!Object.prototype.hasOwnProperty.call(this, name)) {
      Object.defineProperty(this, name, {
        configurable: false,
        enumerable: false,
        writable: true,
        value
      })
    } else {
      this[name] = value
    }

    return oldValue
  }

  updateBeforeApi(field, value) {
    const oldValue = this[field]

    this[field] = value

    return oldValue
  }

  static fromJSON(json) {
    return new this(JSON.parse(JSON.stringify(json)))
  }

  getProp(prop, obj) {
    obj = obj || this

    const parts = prop.split('.')

    for (let i = 0; i < parts.length; i++) {
      obj = obj[parts[i]]

      if (!obj) {
        break
      }
    }

    return obj
  }

  toJSON() {
    return this.getValues(Object.keys(this))
  }

  clone(construct = true) {
    const json = JSON.parse(JSON.stringify(this))

    return construct ? new this.constructor(json) : json
  }
}
