import ApplicantProfileModel from '@/models/ApplicantProfile'

// initial state
const state = () => ({
  data: null,
  applicantProfile: null
})

const getters = {
  data(state) {
    return state.data
  }
}

const actions = {}

// mutations
const mutations = {
  hydrate(state) {
    if (state.data) {
      state.data = new ApplicantProfileModel(state.data)
    }
  },
  setData(state, data) {
    // reset profile on user change
    state.data = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
