import Vue from 'vue'

export default {
  beforeCreate() {
    const sync = this.$options.sync

    if (!sync) {
      return
    }

    if (!this.$options.computed) {
      this.$options.computed = {}
    }

    sync.forEach((key) => {
      if (!Object.prototype.hasOwnProperty.call(this.$attrs, key)) {
        Vue.util.warn(`Missing required sync-prop: "${key}"`, this)
      }

      this.$options.computed[key] = {
        get() {
          return this.$attrs[key]
        },
        set(value) {
          this.$emit('update:' + key, value)
        }
      }
    })
  }
}
