// initial state
const state = () => ({
  // companyApplicants: null,
})

// getters
const getters = {
  // companyApplicants: state => state.companyApplicants,
}

// actions
const actions = {
  // eslint-disable-next-line no-unused-vars
  async findByCompany({ commit }, { company, params }) {
    const uri = company.endpoint + '/applicants'
    const { data } = await this.$axios.get(uri, {
      params
    })

    return data
    // commit('setCompanyApplicants', {
    //   ...data,
    //   docs: data.docs.map(item => new ApplicantModel(item)),
    // })
  }
}

// mutations
const mutations = {
  // hydrate(state) {
  //   if (state.companyApplicants) {
  //     state.companyApplicants.docs = state.companyApplicants.docs.map(item => new ApplicantModel(item));
  //   }
  // },
  // setCompanyApplicants(state, applicants) {
  //   state.companyApplicants = applicants;
  // }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
