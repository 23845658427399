<template>
  <b-modal
    id="user-forgot-password"
    v-model="modal"
    centered
    title="Forgot password"
    modal-class="modal-bb-squared"
    hide-footer
    v-on="$listeners"
  >
    <template slot="default">
      <p class="mb-30">
        No worries! Enter your email address below to receive an email that will
        help you create a new password for your account.
      </p>

      <AlertSlideUpDown :show="!!promptErrorMessage">{{
        promptErrorMessage
      }}</AlertSlideUpDown>

      <AlertSlideUpDown variant="success" :show="promptSuccessMessage"
        >If there's a user connected to this email address, we've sent an email
        with instructions for resetting your password.</AlertSlideUpDown
      >

      <form @submit.prevent="onSubmit">
        <FormItem
          invalid-message="Please enter your email address"
          :state="$v.form.email.$dirty ? !$v.form.email.$error : null"
          required
        >
          <b-form-input
            v-model="form.email"
            :state="$v.form.email.$dirty ? !$v.form.email.$error : null"
            placeholder="Email"
          />
        </FormItem>

        <b-row class="mt-10">
          <b-col sm="4">
            <a class="btn btn-lg btn-outline w-100" to="/" @click="onGotoSignIn"
              >Cancel</a
            >
          </b-col>
          <b-col class="pl-0">
            <button type="submit" class="btn btn-lg w-100">
              <span v-if="!isRequesting">Reset password</span>
              <b-spinner v-else small />
            </button>
          </b-col>
        </b-row>
      </form>
    </template>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import FormItem from '@/components/form/FormItem/index'
import AlertSlideUpDown from '@/components/AlertSlideUpDown'

export default {
  name: 'UserForgotPasswordModal',
  components: {
    AlertSlideUpDown,
    FormItem
  },
  mixins: [validationMixin],
  props: {
    onGotoSignIn: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      modal: true,
      isRequesting: false,
      promptErrorMessage: undefined,
      promptSuccessMessage: undefined,
      form: {
        email: undefined
      }
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      }
    }
  },
  methods: {
    async onSubmit() {
      this.$v.form.$touch()

      if (this.$v.form.$error) {
        return
      }

      if (this.isRequesting) {
        return
      }

      this.promptErrorMessage = undefined
      this.promptSuccessMessage = undefined

      this.isRequesting = true

      try {
        await this.$store.dispatch('user/forgotPassword', {
          email: this.form.email
        })

        this.promptSuccessMessage = true

        this.form.email = ''
        this.$v.$reset()
      } catch (err) {
        this.promptErrorMessage = err.message
      }

      this.isRequesting = false
    }
  }
}
</script>

<style lang="scss"></style>
