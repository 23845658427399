<template>
  <b-modal
    id="userSwitchProfile"
    v-model="model"
    centered
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    modal-class="modal-bb-squared modal-connect-profile"
  >
    <template slot="modal-header">
      <div class="switch-profile-header">
        <h5 class="pb-10">
          Choose your option
        </h5>

        <p>
          Your active profiles on +impact will be listed below. If you want an
          existing profile to be to be listed on The Hub, choose “Continue with
          this profile. Otherwise, to create a new profile, choose the option on
          the bottom “Create a new profile”
        </p>
      </div>
    </template>
    <template slot="default">
      <ul class="user-profile-list">
        <li
          v-for="profile in userProfiles"
          :key="profile.key"
          class="user-profile"
        >
          <button type="button" @click="onSelectProfile(profile)"></button>

          <div class="image">
            <UserAvatar
              :name="profile.name"
              :email="profile.name"
              :image="profile.imageLink"
              size="xl"
            />
          </div>

          <div class="details">
            <div class="name">
              {{ profile.name }}
              <strong v-if="profile.isDraft">(Draft)</strong>
              <span v-else-if="profile.additional" class="text-gray-700"
                >({{ profile.additional }})</span
              >
            </div>
            <div v-if="profile.description" class="type">
              {{ profile.description }}
            </div>
          </div>

          <div class="text-blue-900 fs14 hidden-sm-down">
            <strong>Continue with this profile</strong>
          </div>
        </li>

        <li class="user-profile">
          <button type="button" @click="selectRole"></button>

          <div class="image">
            <div class="image-placeholder">
              <i class="icon-plus text-blue-900"></i>
            </div>
          </div>

          <div class="details">
            <div class="text-blue-900 fs14">
              <strong>+ Create a new profile</strong>
            </div>
          </div>
        </li>
      </ul>
    </template>
  </b-modal>
</template>

<script>
import UserAvatar from '@/components/user/UserAvatar'

export default {
  name: 'SignConnectProfileModal',
  components: {
    UserAvatar
  },
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      rememberSelectedProfile: false
    }
  },
  computed: {
    userProfiles() {
      return this.$store.getters['user/allProfiles']
    },
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    selectRole() {
      this.model = false

      this.$store.commit('modal/chooseRole')
    },
    async onSelectProfile(profile) {
      const endpoint = profile.type === 'company' ? 'companies' : 'investors'
      const data = await this.$axios.$post(
        `${endpoint}/${profile.key}/platform`,
        {
          platform: this.$store.state.platform
        }
      )

      this.$store.commit('user/updateProfile', { profile, data })

      this.$store.dispatch('user/switchProfile', {
        profile: profile,
        save: this.rememberSelectedProfile
      })

      this.$router.push(
        this.$route.query.to ? this.$route.query.to : profile.link
      )

      this.model = false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .modal-connect-profile {
  .modal-dialog {
    max-width: 740px;
  }
}

.switch-profile-header {
  text-align: center;
}

.user-profile-list {
  padding: 0;
  margin: 0;
}

.user-profile {
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px rem(50px);
  transition: background-color 0.3s;
  margin: 0 rem(-50px);

  &::after {
    position: absolute;
    content: '';
    bottom: 0;
    left: rem(50px);
    width: calc(100% - #{rem(100px)});
    height: 1px;
    background-color: $border-color;
  }

  &:first-child::before {
    position: absolute;
    content: '';
    top: 0;
    left: rem(50px);
    width: calc(100% - #{rem(100px)});
    height: 1px;
    background-color: $border-color;
  }

  &:hover {
    background-color: $gray-100;
  }

  button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    cursor: pointer;
  }

  .image {
    margin-right: rem(25px);

    .image-placeholder {
      width: 64px;
      height: 64px;
      border: 2px dashed $border-color;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 24px;
      }
    }
  }

  .details {
    flex: 1 1 auto;
  }

  .type {
    color: $gray-700;
    font-size: rem(14px);
  }
}
</style>
