export default {
  computed: {
    userIsLoggedIn() {
      return this.$store.getters['user/isLoggedIn']
    }
  },
  methods: {
    redirectToLogin() {
      if (this.userIsLoggedIn) {
        this.$store.dispatch('user/logout')
      }

      this.$router.replace({
        path: '/',
        query: {
          redirect: this.$route.fullPath
        }
      })
    }
  }
}
