export default function() {
  window._linkedin_data_partner_id = '226868'
  ;(function() {
    const s = document.getElementsByTagName('script')[0]
    const b = document.createElement('script')
    b.type = 'text/javascript'
    b.async = true
    b.setAttribute('data-category-consent', 'cookie_cat_marketing')
    b.setAttribute(
      'data-consent-src',
      'https://snap.licdn.com/li.lms-analytics/insight.min.js'
    )
    s.parentNode.insertBefore(b, s)
  })()

  console.log('linkedin: insight installed')
}
