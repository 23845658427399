<template>
  <b-modal
    id="userSwitchProfile"
    v-model="model"
    centered
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    modal-class="modal-bb-squared"
  >
    <template slot="modal-header">
      <div class="switch-profile-header">
        <h5 class="pb-10">Choose profile</h5>
        <div>
          <b-form-checkbox v-model="rememberSelectedProfile" :value="true">
            <span class="fs15 text-gray-700">
              Remember my choice next time I log in
            </span>
          </b-form-checkbox>
        </div>
      </div>
    </template>
    <template slot="default">
      <ul class="user-profile-list">
        <li v-for="profile in userProfiles" :key="profile.key" class="user-profile">
          <button @click="onSelectProfile(profile)"></button>

          <div class="image">
            <UserAvatar
              :name="profile.name"
              :email="profile.name"
              :image="profile.imageLink"
              size="xl"
            />
          </div>

          <div class="details">
            <div class="name">
              {{ profile.name }}
              <strong v-if="profile.isDraft">(Draft)</strong>
              <span v-else-if="profile.additional" class="text-gray-700"
                >({{ profile.additional }})</span
              >
            </div>
            <div v-if="profile.description" class="type">
              {{ profile.description }}
            </div>
          </div>
        </li>
      </ul>
    </template>
  </b-modal>
</template>

<script>
import UserAvatar from '@/components/user/UserAvatar';

export default {
  name: 'SignSwitchProfileModal',
  components: {
    UserAvatar
  },
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      rememberSelectedProfile: false
    };
  },
  computed: {
    userProfiles() {
      // return this.$store.getters['user/profiles']
      const onlyStartups = this.$store.getters['user/onlyStartups'];
      return onlyStartups
        ? this.$store.getters['user/startupProfiles']
        : this.$store.getters['user/profiles'];
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    async onSelectProfile(profile) {
      this.$store.dispatch('user/switchProfile', {
        profile: profile,
        save: this.rememberSelectedProfile
      });

      this.$store.dispatch('user/setOnlyStartups', false);

      this.$router.push(this.$route.query.to ? this.$route.query.to : profile.link);

      this.model = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.switch-profile-header {
  text-align: center;
}

.user-profile-list {
  padding: 0;
  margin: 0;
}

.user-profile {
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px 0;
  margin-bottom: 10px;
  border-top: 1px solid $border-color;
  transition: background-color 0.3s;

  &:last-child {
    border-bottom: 1px solid $border-color;
  }

  &:hover {
    background-color: $gray-100;
  }

  button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    cursor: pointer;
  }

  .image {
    margin-right: rem(25px);
  }

  .type {
    color: $gray-700;
    font-size: rem(14px);
  }
}
</style>
