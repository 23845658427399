import PartnerModel from '@/models/Partner'

// initial state
const state = () => ({
  partners: [],
  bodyText: null
})

// getters
const getters = {}

// actions
const actions = {
  async get({ commit }) {
    const { data } = await this.$axios.get('pages/partners')

    commit('setPage', {
      partners: data.page.partners.map((item) => new PartnerModel(item)),
      bodyText: data.page.content
    })
  }
}

// mutations
const mutations = {
  hydrate(state) {
    if (state.partners) {
      state.partners = state.partners.map((item) => new PartnerModel(item))
    }
  },
  setPage(state, { partners, bodyText }) {
    state.partners = partners
    state.bodyText = bodyText
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
