<template>
  <BaseLayout>
    <div class="jobwidget-layout">
      <div class="jobwidget-layout__body">
        <slot>
          <content>
            <nuxt class="jobwidget-layout__body__content" />
          </content>
        </slot>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/layouts/base'

export default {
  name: 'JobListWidgetLayout',
  components: {
    BaseLayout
  }
}
</script>

<style lang="scss">
.jobwidget-layout {
  &__body {
    display: block;
    background-color: #f5f7fa;

    &__content {
      padding-top: 0;
      min-height: 0;

      @include media-breakpoint-down(sm) {
        min-height: 0;
      }
    }
  }
}
</style>
