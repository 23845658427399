<template>
  <div>
    <div class="nav-manager">
      <b-nav-item
        :to="userCurrentProfile.link"
        :class="$route.path === userCurrentProfile.link ? 'menu-active' : ''"
      >
        <span v-if="!userHasAnyProfile" :title="userCurrentProfile.name">
          {{ userCurrentProfile.name }}
        </span>
        <span v-else title="My dashboard">My dashboard</span>
      </b-nav-item>

      <b-nav-item-dropdown no-caret right size="lg">
        <template slot="button-content">
          <UserAvatar
            slot="button-content"
            :name="userCurrentProfile.name"
            :email="userCurrentProfile.name"
            :image="userCurrentProfile.imageLink"
          />
          <i class="icon-caret"></i>
        </template>

        <div class="profile">
          <div class="image">
            <UserAvatar
              slot="button-content"
              :name="userCurrentProfile.name"
              :email="userCurrentProfile.name"
              :image="userCurrentProfile.imageLink"
              size="large"
            />
          </div>

          <div class="details">
            <div class="name">{{ userCurrentProfile.name }}</div>
            <div v-if="!userHasSelectedProfile" class="type">User</div>
            <div v-else class="type">
              {{ userCurrentProfile.typeLabel }}
              <span v-if="userCurrentProfile.additional"
                >({{ userCurrentProfile.additional }})</span
              >
            </div>
          </div>

          <button
            v-if="userHasMultipleProfiles"
            class="btn btn-link switch-profile"
            @click="onSwitchProfile"
          >
            Switch profile
          </button>
        </div>

        <div
          v-if="userHasSelectedProfile"
          style="border-top: 1px solid #dadce1"
        >
          <b-dropdown-item
            v-for="(item, i) in profileMenuItems"
            :key="i"
            :to="item.url"
          >
            <img
              :src="require(`~/assets/img/icons/menu/${item.icon}.svg`)"
              :alt="`${item.label}`"
            />
            <span>{{ item.label }}</span>
          </b-dropdown-item>
          <div v-if="applicantProfileMenuItem">
            <b-dropdown-item
              v-for="(item, i) in applicantProfileMenuItem"
              :key="i"
              :to="item.url"
            >
              <img
                :src="require(`~/assets/img/icons/menu/${item.icon}.svg`)"
                :alt="`${item.label}`"
              />
              <span>{{ item.label }}</span>
            </b-dropdown-item>
          </div>
        </div>

        <b-dropdown-item to="/settings" style="border-top: 1px solid #dadce1">
          <img src="~/assets/img/icons/menu/account.svg" alt="" />
          <span>My Account</span>
        </b-dropdown-item>
        <b-dropdown-item @click="onSignOut">
          <img src="~/assets/img/icons/menu/logout.svg" alt="" />
          <span>Log out</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </div>
  </div>
</template>

<script>
import UserAvatar from '@/components/user/UserAvatar'
import ApplicantProfileModel from '@/models/ApplicantProfile'

export default {
  name: 'UserProfileDropdown',
  components: {
    UserAvatar
  },
  data: () => ({
    applicantProfile: undefined,
    applicantProfileMenuItem: []
  }),
  computed: {
    isLoggedIn() {
      return this.$store.getters['user/isLoggedIn']
    },
    userEmail() {
      return this.$store.state.user.data.email
    },
    userHasSelectedProfile() {
      return this.$store.getters['user/hasSelectedProfile']
    },
    userHasMultipleProfiles() {
      return this.$store.getters['user/hasMultipleProfiles']
    },
    userCurrentProfile() {
      return this.$store.getters['user/currentProfile']
    },
    userHasAnyProfile() {
      return this.$store.getters['user/hasAnyProfile']
    },
    profileMenuItems() {
      switch (this.userCurrentProfile.type) {
        case 'company':
          return [
            {
              icon: 'edit',
              url: `${this.userCurrentProfile.link}/edit`,
              label: 'Edit startup profile'
            },
            {
              icon: 'settings',
              url: `${this.userCurrentProfile.link}/settings`,
              label: 'Startup settings'
            },
            {
              icon: 'applicants',
              url: `${this.userCurrentProfile.link}/applicants`,
              label: 'All applicants'
            },
            {
              icon: 'faq',
              url: `/startups/faq`,
              label: 'FAQ'
            }
          ]
        case 'investor':
          return [
            {
              icon: 'edit',
              url: `${this.userCurrentProfile.link}/edit`,
              label: 'Edit investor profile'
            },
            {
              icon: 'settings',
              url: `${this.userCurrentProfile.link}/settings`,
              label: 'Investor settings'
            }
          ]

        case 'applicant':
          return [
            {
              icon: 'settings',
              url: `/client/applicant/settings`,
              label: 'Quick apply'
            }
          ]

        default:
          return []
      }
    }
  },
  async mounted() {
    try {
      // TO DO: Move into $store and hydrate
      if (
        this.isLoggedIn &&
        this.userEmail &&
        this.userCurrentProfile.type === 'applicant'
      ) {
        const { data } = await this.$axios.get('digest/mail/' + this.userEmail)
        this.applicantProfile = new ApplicantProfileModel(data)
        this.applicantProfileMenuItem.push({
          icon: 'notifications',
          url: `/profile-settings/${this.applicantProfile.settingsKey}`,
          label: 'Email notifications'
        })
      }
    } catch (err) {
      console.log('err', err)
    }
  },
  methods: {
    onSwitchProfile() {
      this.$store.commit('modal/switchProfile')
    },
    async onSignOut() {
      try {
        await this.$store.dispatch('user/logout')
        this.$router.push('/')
      } catch (err) {
        console.error(err)
      }
    }
  }
}
</script>

<style lang="scss">
.nav-manager {
  display: flex;
  flex-direction: row;

  .nav-link:not(.dropdown-toggle) {
    overflow: hidden;
    max-width: rem(150px);

    span {
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .menu-active {
    .nav-link:not(.dropdown-toggle) {
      &::before {
        opacity: 1;
      }

      span::after {
        font-weight: 700;
        color: $black;
      }
    }
  }

  .b-nav-dropdown {
    display: flex;

    .dropdown-toggle {
      margin: auto auto auto 0 !important;
      padding-left: 0 !important;
      padding-right: rem(10px) !important;

      i {
        font-size: rem(10px);
        color: $blue-900;
        margin-left: rem(5px);
      }

      .img {
        height: rem(32px);
        width: rem(32px);
        min-width: rem(32px);
        // background-color: #000;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }

  .dropdown-menu {
    position: absolute;
    box-shadow: 0 rem(2px) rem(8px) $gray-500;
    border: 0;
    margin-top: 0;
    top: 50%;
    right: rem(20px);
    padding: rem(4px) 0;
    width: rem(240px);

    .dropdown-item {
      font-size: rem(13px);
      padding-top: 0.6rem;
      padding-bottom: 0.6rem;

      img {
        width: 22px;
        height: 22px;
        object-fit: contain;
        margin-right: 10px;
      }
    }
  }

  .profile {
    display: flex;
    position: relative;
    padding: rem(15px) rem(20px);

    .image {
      margin-right: rem(15px);
    }

    .name {
      font-size: rem(13px);
      font-weight: bold;
    }

    .type {
      font-size: rem(12px);
      color: $gray-700;
    }

    .switch-profile {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: rem(13px);
      display: block;
      padding: rem(8px);
      height: rem(24px);
    }
  }

  @include media-breakpoint-down(md) {
    flex-direction: row;
    align-items: center;

    .nav-link {
      height: auto;
    }
  }

  @include media-breakpoint-down(sm) {
    .nav-item:not(.b-nav-dropdown) {
      display: none;
    }
  }
}
</style>
