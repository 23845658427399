<template>
  <BaseLayout>
    <div class="blog-layout">
      <div class="blog-layout__body">
        <slot>
          <content>
            <nuxt class="blog-layout__body__content" />
          </content>
        </slot>
      </div>

      <slot name="header">
        <div class="blog-layout__header">
          <Headerbar />
        </div>
      </slot>
    </div>
  </BaseLayout>
</template>

<script>
import Headerbar from '@/components/Headerbar'
import BaseLayout from '@/layouts/base'

export default {
  name: 'DefaultLayout',
  components: {
    BaseLayout,
    Headerbar
  }
}
</script>

<style lang="scss" scoped>
body,
html {
  overflow: hidden;
}

.blog-layout {
  &__header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    height: $app-header-height;
    background: #fff;
  }

  &__body {
    display: block;
    overflow: hidden;

    &__content {
      overflow: hidden;
    }
  }
}
</style>
