const MODAL_SHOW_AUTH_FLOW = 'MODAL_SHOW_AUTH_FLOW'
const MODAL_HIDE_AUTH_FLOW = 'MODAL_HIDE_AUTH_FLOW'

const state = () => ({
  authFlow: {
    initialRoute: undefined,
    isVisible: false,
    onCancelCallback: undefined,
    onAuthSuccessCallback: undefined,
    disableSignUp: false,
    defaults: {}
  },
  switchProfile: false,
  chooseRole: false,
  connectProfile: false
})

const getters = {
  authFlow(state) {
    return state.authFlow
  }
}

const actions = {
  auth({ commit }, options) {
    commit(MODAL_SHOW_AUTH_FLOW, options)
  },
  cancel({ commit }) {
    commit(MODAL_HIDE_AUTH_FLOW)
  }
}

const mutations = {
  [MODAL_SHOW_AUTH_FLOW](
    state,
    {
      initialRoute,
      onCancel,
      onAuthSuccess,
      defaults = {},
      disableSignUp = false
    }
  ) {
    state.authFlow.isVisible = true
    state.authFlow.initialRoute = initialRoute
    state.authFlow.onCancelCallback = onCancel
    state.authFlow.onAuthSuccessCallback = onAuthSuccess
    state.authFlow.disableSignUp = disableSignUp
    state.authFlow.defaults = defaults
  },
  [MODAL_HIDE_AUTH_FLOW](state) {
    state.authFlow.isVisible = false
  },
  switchProfile(state, value = true) {
    state.switchProfile = value
  },
  chooseRole(state, value = true) {
    state.chooseRole = value
  },
  connectProfile(state, value = true) {
    state.connectProfile = value
  }
}

export default {
  namespaced: true,

  state,
  actions,
  mutations,
  getters
}
