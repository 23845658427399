import BaseModel from './BaseModel'
import Company from './Company'
import Investor from './Investor'
import Image from '@/models/Image'
import ApplicantProfile from './ApplicantProfile'

export default class User extends BaseModel {
  constructor(data) {
    super(data)

    this.isLoading = false
  }

  get fullName() {
    return `${this.name.first} ${this.name.last}`
  }

  get isPending() {
    return false
  }

  get imageUrl() {
    return this.image ? this.image.thumbnailSizeUrl : null
  }
}

User.mapping = {
  companies: Company,
  investors: Investor,
  image: Image,
  applicantProfile: ApplicantProfile
}
