<template>
  <BaseLayout>
    <div class="default-layout">
      <slot name="header">
        <Headerbar />
      </slot>

      <div class="default-layout__body">
        <slot>
          <content>
            <nuxt class="default-layout__body__content" />
          </content>
        </slot>
      </div>

      <slot name="footer">
        <Footerbar />
      </slot>
    </div>
  </BaseLayout>
</template>

<script>
import Headerbar from '@/components/Headerbar'
import Footerbar from '@/components/Footerbar'
import BaseLayout from '@/layouts/base'
import {
  // chatCrutch,
  // chatDestroy,
  chatLoad
  // chatInit
} from '../services/chatHelper.js'

export default {
  name: 'DefaultLayout',
  components: {
    BaseLayout,
    Headerbar,
    Footerbar
  },
  head() {
    // console.log('wrapper head, this', this.user);
    return {
      script: [chatLoad({ user: this.user, isHidden: true })]
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/data']
    }
  },
  mounted() {
    // console.log('wrapper mounted, this.user', this.user);
    // setTimeout(()=>{chatDestroy()},5000);
  }
}
</script>

<style lang="scss">
.default-layout {
  &:has(.header-info) {
    .default-layout__body__content {
      margin-top: 5rem !important;
    }
  }

  &__body {
    display: block;

    &__content {
      padding-top: $app-header-height;
      min-height: calc(100vh - 414px);

      @include media-breakpoint-down(sm) {
        min-height: calc(100vh - 414px);
      }
    }
  }
}
</style>
