import find from 'lodash/find'

/**
 * if requested company (ex: /client/:company/applicants) is still in DRAFT,
 * redirect the user to the form, to complete the profile.
 */
export default function({ store, params, redirect }) {
  const user = store.getters['user/data']
  if (!user) {
    return
  }

  const investor = find(user.investors, { key: params.investor })
  if (investor && investor.status === 'DRAFT') {
    redirect(`/client/investors/${investor.key}/edit`)
  }
}
