<template>
  <!-- <div v-if="this.showBanner" class="header-info"> -->
  <div v-if="false" class="header-info">
    <div></div>
    <!-- <p>
      Early stage startup? Apply for our brand new
      <a class="link" :href="communityProgramUrl" target="_blank">
        Community Program
      </a>
      and continue using The Hub for free 🚀
    </p> -->
    <!-- <p>
      🎅 Happy Holidays! Here's a small gift 🎁 Get <b>10% off</b> on
      <b>PRO</b> during all of December. Use promo code <b>XMAS10</b> during
      checkout to unlock.
    </p> -->
    <!-- <p>
      🤖<b>CYBER MONDAY</b>🤖 Get <b>20% off</b> on <b>PRO</b> job ads
      <b>today</b>. Use promo code <b>CYBERMONDAY20</b> during checkout to
      unlock.
    </p> -->
    <!-- <p>
      🚀Product launch🚀
      <a href="/pricing" alt="Read more about our changes" target="_blank"
        >Click here</a
      >
      to learn more about The Hub’s new products.
    </p> -->
    <span class="icon-close" @click="hide()"></span>
    <!-- <ui-feature-indicator name="infoHeaderBanner" /> -->
  </div>
</template>

<script>
//import UiFeatureIndicator from '@/components/UiFeatureIndicator.vue'
export default {
  name: 'InfoHeaderBanner',
  //components: { UiFeatureIndicator },
  data() {
    return {
      showBanner: false
    };
  },
  watch: {
    $route() {
      this.setVisibility();
    }
  },
  created() {
    this.setVisibility();
  },
  computed: {
    communityProgramUrl() {
      // console.log('communityProgramUrl', process.env.BASE_URL + 'community-program');
      return process.env.BASE_URL + '/community-program';
    },
    isLoggedIn() {
      return this.$store.getters['user/isLoggedIn'];
    },
    userCurrentProfile() {
      return this.$store.getters['user/currentProfile'];
    }
  },
  methods: {
    hide() {
      this.showBanner = !this.showBanner;
      //this.reachUiFeature('infoHeaderBanner')
    },
    setVisibility() {
      // console.log(
      //   ' banner setVisibility ????? this.$route?',
      //   this.$route?.fullPath
      // )

      // Регулярное выражение

      // Примеры строк для проверки
      // const validPath = "/client/startups/myStartup";
      // const invalidPath = "/client/startups/my/Startup";

      // Функция для проверки строки
      function isValidPath(path) {
        const regex = /^\/client\/startups\/[^/]+$/;
        return regex.test(path);
      }

      // Проверка примеров
      // console.log(isValidPath(validPath));   // Вывод: true
      // console.log(isValidPath(invalidPath)); // Вывод: false

      const path = this.$route?.fullPath || '';
      if (path === '/startups/join' || path === '/pricing' || isValidPath(path)) {
        // if (path.includes('/client') || path === '/startups/join' || path === '/pricing') {
        // contains /client/ or matches exactly https://thehub.io/startups/join or https://thehub.io/pricing
        this.showBanner = true;
      } else {
        this.showBanner = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.header-info {
  display: flex;
  justify-content: space-between;
  color: white;
  font-size: 16px;
  text-align: center;
  margin-bottom: 40px;
  padding: 10px;
  // background-color: #24cf94;
  // background-color: black;
  // background-color: #f71f40;
  background-color: #443fdb;

  .icon-close {
    font-size: 10px;
    margin-left: 1rem;
    align-self: center;
    cursor: pointer;
  }

  a {
    font-weight: bold;
    text-decoration: none;
    // margin-right: 3px;
    &:hover {
      color: white;
      text-decoration: underline;
    }
  }
}
</style>
