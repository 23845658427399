/* eslint prefer-promise-reject-errors: 0 */
export default function({ $axios, store, redirect }) {
  $axios.onResponseError((error) => {
    /**
     * user not authorized, most likely because auth token expired, or user is not logged in.
     */
    if (error.response && error.response.status === 403) {
      if (store.getters['user/isLoggedIn']) {
        store.dispatch('user/logout')

        // https://github.com/nuxt/nuxt.js/issues/1298
        redirect('/')
        return
      }
    }

    if (error.response) {
      return Promise.reject({
        message: error.response.data.message,
        status: error.response.status,
        error
      })
    }
  })
}
