import BaseModel from './BaseModel'
import Investor from './Investor'

export default class StartupInvestor extends BaseModel {
  update(data, vue) {
    super.update(data, vue)
  }

  get displayName() {
    return this.investor ? this.investor.name : this.name
  }

  get displayCategory() {
    return this.investor ? this.investor.category : this.category
  }

  get logoImageUrl() {
    return this.investor ? this.investor.logoImageUrl : null
  }

  get intro() {
    return this.investor && this.investor.basicInfo
      ? this.investor.basicInfo.intro
      : null
  }

  get websiteUrl() {
    if (/^https?:\/\//.test(this.website)) {
      return this.website
    }

    return 'http://' + this.website
  }

  getUrl(store) {
    if (this.investor) {
      let url = this.investor.url

      const platform = store.state.platform

      if (!this.investor.platform[platform]) {
        if (platform === 'plusimpact') {
          url = process.env.THEHUB_URL + url
        } else {
          url = process.env.PLUSIMPACT_URL + url
        }
      }

      return url
    }

    return this.websiteUrl
  }

  get isEditable() {
    return !this.investor
  }
}

StartupInvestor.mapping = {
  investor: Investor
}
