// import VuexPersist from 'vuex-persist';
// import VuexPlugins from './vuex-plugins';

// const version = 2;
// const vuexLocalStorage = new VuexPersist({
//   key: `vuex-${version}`,
//   storage: typeof window !== 'undefined' ? window.localStorage : {},
//   modules: [
//     'user'
//   ],
// })

export const state = () => ({
  version: process.env.VERSION,
  platform: process.env.PLATFORM || 'thehub'
})

export const mutations = {
  setVersion(state, version) {
    state.version = version
  }
}

export const actions = {
  async nuxtServerInit({ dispatch }, { query }) {
    /**
     * if query parameter "email-verification-key" defined, then try to auto verify email (fails silently)
     */
    if (query['email-verification-key']) {
      try {
        await dispatch('user/verifyEmail', {
          token: query['email-verification-key']
        })
      } catch (err) {}
    }

    await Promise.all([dispatch('user/load'), dispatch('meta/load')])

    dispatch('user/loadProfile')
  },
  nuxtClientInit({ commit }) {
    commit('user/hydrate')
    commit('applicantprofile/hydrate')
    commit('jobs/hydrate')
    commit('startups/hydrate')
    commit('investors/hydrate')
    commit('partners/hydrate')
    commit('tools/hydrate')
  }
}

export const plugins = [
  // VuexPlugins,
  // vuexLocalStorage.plugin
]
