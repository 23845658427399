/**
 * middleware for all `/client/applicant/...` routes.
 */
export default function({ store, route, redirect }) {
  const user = store.getters['user/data']

  if (!user || (!user.applicant.enabled && !route.query.create)) {
    return redirect('/')
  }
}
