<template>
  <span
    class="avatar"
    :class="{
      ['size-' + size]: true,
      'has-image': image,
      transparent,
      'is-anonymised': anonymised
    }"
    :style="style"
  >
    {{ image ? '' : inicials }}
  </span>
</template>

<script>
export default {
  name: 'UserAvatar',
  props: {
    name: String,
    email: String,
    image: String,
    size: {
      type: String,
      default: 'medium'
    },
    transparent: Boolean,
    anonymised: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inicials() {
      return (this.name || '')
        .replace(/\s+/g, ' ')
        .replace(/^(mr|ms|mrs)\.?\s+/i, '')
        .split(' ')
        .filter((word) => word)
        .map((word) => word.substr(0, 1).toUpperCase())
        .slice(0, 2)
        .join('')
    },
    style() {
      return this.image
        ? {
            'background-image': 'url(' + this.image + ')'
          }
        : this.avatarStyle(this.name || '', this.email || '')
    }
  },
  methods: {
    avatarStyle(name, email) {
      const colors = [
        '#ffb13b',
        '#dd5f7a',
        '#8dd43a',
        '#2f7e2f',
        '#4141b9',
        '#3dafea',
        '#7e3e3e',
        '#f2f26b',
        '#864ba3',
        '#ac8aef',
        '#f2f2ce',
        '#379d9d',
        '#ff6750',
        '#8691a2',
        '#d2fd8d',
        '#88eadf'
      ]

      const texts = [
        '#FEFEFE',
        '#FEFEFE',
        '#FEFEFE',
        '#FEFEFE',
        '#FEFEFE',
        '#FEFEFE',
        '#FEFEFE',
        '#333333',
        '#FEFEFE',
        '#FEFEFE',
        '#333333',
        '#FEFEFE',
        '#FEFEFE',
        '#FEFEFE',
        '#333333',
        '#333333'
      ]

      const id = email || name

      const index =
        id.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0) %
        colors.length

      return {
        'background-color': colors[index],
        color: texts[index]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar {
  flex: 0 0 auto;
  height: 32px;
  width: 32px;
  background: $green no-repeat center center / cover;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  font-weight: bold;

  &.transparent {
    background-color: transparent;
  }

  &.is-anonymised {
    background-color: $gray-600 !important;
    color: white !important;
  }

  &.has-image {
    background-color: transparent;
  }

  &.size-large {
    width: 48px;
    height: 48px;
  }

  &.size-xl {
    width: 64px;
    height: 64px;
  }

  &.size-investor {
    width: 100px;
    height: 100px;
    font-size: rem(34px);
  }

  &.size-profile {
    width: 140px;
    height: 140px;
    font-size: rem(34px);
  }
}
</style>
