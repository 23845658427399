<template>
  <b-modal
    v-model="modal"
    centered
    title="Sign Up"
    modal-class="modal-bb-squared"
    footer-class="light-blue"
    v-on="$listeners"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <b-row align-v="end">
        <b-col>
          <h5>Sign up with Email</h5>
        </b-col>
        <b-col class="text-right">
          <div class="fs14">
            or Sign up with
            <button class="btn btn-link fs14" @click="onAuthorizeWithLinkedin">
              LinkedIn
            </button>
          </div>
        </b-col>
      </b-row>
      <button class="close" @click="close()"></button>
    </template>

    <template slot="default">
      <AlertSlideUpDown :show="!!promptErrorMessage">{{
        promptErrorMessage
      }}</AlertSlideUpDown>

      <form @submit.prevent="onSubmit">
        <b-form-row>
          <b-col sm="6">
            <FormItem
              invalid-message="Please enter first name"
              :state="
                $v.form.name.first.$dirty ? !$v.form.name.first.$error : null
              "
              required
            >
              <b-form-input
                v-model="form.name.first"
                maxlength="50"
                :state="
                  $v.form.name.first.$dirty ? !$v.form.name.first.$error : null
                "
                placeholder="First name"
              />
            </FormItem>
          </b-col>

          <b-col>
            <FormItem
              invalid-message="Please enter last name"
              :state="
                $v.form.name.last.$dirty ? !$v.form.name.last.$error : null
              "
              required
            >
              <b-form-input
                v-model="form.name.last"
                maxlength="50"
                :state="
                  $v.form.name.last.$dirty ? !$v.form.name.last.$error : null
                "
                placeholder="Last name"
              />
            </FormItem>
          </b-col>
        </b-form-row>

        <FormItem
          invalid-message="Please enter your email address"
          :state="$v.form.email.$dirty ? !$v.form.email.$error : null"
          required
        >
          <b-form-input
            v-model="form.email"
            maxlength="50"
            :state="$v.form.email.$dirty ? !$v.form.email.$error : null"
            placeholder="Email"
          />
        </FormItem>

        <FormItem
          invalid-message="Please enter a valid password"
          :state="$v.form.password.$dirty ? !$v.form.password.$error : null"
          required
        >
          <b-form-input
            v-model="form.password"
            maxlength="50"
            type="password"
            :state="$v.form.password.$dirty ? !$v.form.password.$error : null"
            placeholder="Password"
          />

          <FormPasswordChecker class="mt-10" :password="form.password" />
        </FormItem>

        <FormItem
          :state="
            $v.form.reCaptchaResponse.$dirty
              ? !$v.form.reCaptchaResponse.$error
              : null
          "
          invalid-message="Please verify you are not a robot"
        >
          <VueRecaptcha
            ref="reCaptcha"
            :sitekey="siteKey"
            :load-recaptcha-script="true"
            @verify="onVerify"
            @expired="onExpired"
          />
        </FormItem>

        <button type="submit" class="btn btn-lg w-100 mb-16">
          <span v-if="!isRequesting">Sign up</span>
          <b-spinner v-else small />
        </button>

        <div class="fs14 mt-10">
          By signing up you confirm that you accept our
          <router-link to="/terms" target="_blank" class="text-blue-900"
            >Terms of Service</router-link
          >
          and
          <router-link
            to="/privacy-policy"
            target="_blank"
            class="text-blue-900"
            >Privacy Policy</router-link
          >.
        </div>
      </form>
    </template>
    <template slot="modal-footer">
      <div>
        <span>Already have an account? </span>
        <button class="btn btn-link fs14" @click="onGotoSignIn">
          Log in
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import { validationMixin } from 'vuelidate'
import { required, minLength, email } from 'vuelidate/lib/validators'
import cloneDeep from 'lodash/cloneDeep'
import FormItem from '@/components/form/FormItem/index'
import FormPasswordChecker from '@/components/form/FormPasswordChecker/index'
import AlertSlideUpDown from '@/components/AlertSlideUpDown'
import passwordChecker from '@/services/passwordChecker'

export default {
  name: 'UserSignUpModal',
  components: {
    AlertSlideUpDown,
    FormItem,
    VueRecaptcha,
    FormPasswordChecker
  },
  mixins: [validationMixin],
  props: {
    onGotoSignIn: {
      type: Function,
      required: true
    },
    onAuthorizeWithLinkedin: {
      type: Function,
      required: true
    },
    defaults: {
      type: Object
    }
  },
  data() {
    return {
      modal: true,
      siteKey: process.env.GOOGLE_RECAPTCHA_SITE_KEY,
      promptErrorMessage: undefined,
      isRequesting: false,
      form: {
        name: {
          first: undefined,
          last: undefined
        },
        email: undefined,
        password: undefined,
        reCaptchaResponse: undefined
      }
    }
  },
  mounted() {
    this.form.email = this.defaults.email || undefined

    const name = (this.defaults.name || '').split(' ')

    if (name) {
      this.form.name.first = name[0]
      this.form.name.last = name[1]
    }
  },
  methods: {
    async onSubmit() {
      if (this.isRequesting) {
        return
      }

      this.$v.form.$touch() // will trigger errors to appear.

      if (this.$v.form.$error) {
        return
      }

      this.isRequesting = true

      const email = this.form.email

      try {
        await this.$store.dispatch('user/signUp', cloneDeep(this.form))
      } catch (err) {
        if (
          err.error &&
          err.error.response &&
          err.error.response.data.details &&
          err.error.response.data.details.code === 'E_ACCOUNT_EXISTS'
        ) {
          this.$emit('exists', email)
        }

        this.promptErrorMessage = err.message
      }

      if (this.$refs.reCaptcha) {
        this.$refs.reCaptcha.reset()
      }

      this.form.reCaptchaResponse = undefined

      this.isRequesting = false
    },
    onVerify(response) {
      this.form.reCaptchaResponse = response
      this.$v.form.reCaptchaResponse.$touch()
    },
    onExpired() {
      this.form.reCaptchaResponse = undefined
      this.$v.form.reCaptchaResponse.$touch()
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      name: {
        first: {
          required
        },
        last: {
          required
        }
      },
      password: {
        password(value) {
          if (!value) {
            return true
          }
          return Object.values(passwordChecker(value)).every(
            (value) => value === true
          )
        },
        required,
        minLength: minLength(8)
      },
      reCaptchaResponse: {
        required
      }
    }
  }
}
</script>
