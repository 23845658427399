import Vue from 'vue';
import Router from 'vue-router';
import startsWith from 'lodash/startsWith';
import replace from 'lodash/replace';

Vue.use(Router);

export function createRouter(ssrContext, createDefaultRouter, routerOptions) {
  const options = routerOptions || createDefaultRouter(ssrContext).options;
  const hostname = ssrContext ? ssrContext.req.headers.host : location.host;

  return new Router({
    ...options,
    routes: resolveRoutes(options.routes, hostname)
  });
}

function resolveRoutes(defaultRoutes, hostname) {
  hostname = hostname.replace(/^(www\.)/, '');

  const thehubDomains = [
    'thehub.io',
    'hubtest.io',
    'thehub-frontend--staging.herokuapp.com',
    'localhost:8000'
  ];

  return thehubDomains.indexOf(hostname) == -1
    ? careerRoutes(defaultRoutes)
    : thehubRoutes(defaultRoutes);
}

function thehubRoutes(routes) {
  return routes.filter((r) => !startsWith(r.name, 'careers'));
}

function careerRoutes(routes) {
  return routes
    .filter((r) => startsWith(r.name, 'careers'))
    .map((r) => {
      return {
        ...r,
        path: r.path == '/careers' ? '/' : replace(r.path, '/careers', '')
      };
    });
}
