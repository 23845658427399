import BaseModel from './BaseModel'
import Image from './Image'

export default class Partner extends BaseModel {
  get websiteUrl() {
    return this.website && this.website.match(/^https?:/)
      ? this.website
      : 'http://' + this.website
  }
}

Partner.mapping = {
  logo: Image
}
