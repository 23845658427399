import find from 'lodash/find'

/**
 * middleware for all `/client/:company/....` routes.
 */
export default function({ store, params, redirect }) {
  const user = store.getters['user/data']

  // admins have access to all companies.
  if (user && user.isAdmin) {
    return
  }

  if (!store.getters['user/isLoggedIn']) {
    return redirect('/')
  }

  const company = find(user.companies, { key: params.company })

  // not manager of the requested company redirect to own company page.
  if (!company) {
    return redirect('/client/')
  }
}
