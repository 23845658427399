import head from 'lodash/head'
import BaseModel from './BaseModel'

export default class Location extends BaseModel {
  get shortName() {
    return this.locality || head(this.address && this.address.split(','))
  }
  get countryName() {
    return this.country || '-'
  }
}

Location.mapping = {}
