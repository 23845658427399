<template>
  <b-modal
    id="userSignExists"
    v-model="modal"
    centered
    title="Email address found, please log in"
    hide-footer
    modal-class="modal-bb-squared"
    footer-class="light-blue"
    v-on="$listeners"
  >
    <template slot="default">
      <form @submit.prevent="onSubmit">
        <p class="pb-30">
          The Hub and +Impact share the same log in. This means you can use the
          same log in credentials for both platforms. We’ve found your email
          address existing on one of the platforms. Please log in with your
          credentials to continue. If you don’t remember your credentials,
          please use the “Forgot password” option to reset your password.
        </p>

        <AlertSlideUpDown :show="promptInvalidLoginError"
          >Invalid email or password</AlertSlideUpDown
        >

        <FormItem
          class="mb-16"
          invalid-message="Please enter your email address"
          :state="$v.form.email.$dirty ? !$v.form.email.$error : null"
          required
        >
          <b-form-input
            v-model="form.email"
            :state="$v.form.email.$dirty ? !$v.form.email.$error : null"
            placeholder="Email"
            readonly
          />
        </FormItem>

        <FormItem
          class="mb-16"
          invalid-message="Please enter your password"
          :state="$v.form.password.$dirty ? !$v.form.password.$error : null"
          required
        >
          <b-form-input
            v-model="form.password"
            :state="$v.form.password.$dirty ? !$v.form.password.$error : null"
            type="password"
            placeholder="Password"
          />
        </FormItem>

        <div class="text-right pb-20">
          <a class="text-blue-900 fs14" @click="onGotoForgotPassword">
            Forgot password?
          </a>
        </div>
        <button class="btn btn-lg w-100 mb-16" type="submit">
          <span v-if="!isRequestingSignIn">Log in</span>
          <b-spinner v-else small />
        </button>
      </form>
    </template>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import FormItem from '@/components/form/FormItem/index'
import AlertSlideUpDown from '@/components/AlertSlideUpDown'

export default {
  name: 'UserSignExistsModal',
  components: {
    FormItem,
    AlertSlideUpDown
  },
  mixins: [validationMixin],
  props: {
    onGotoForgotPassword: {
      type: Function,
      required: true
    },
    email: {
      type: String,
      default: null
    }
  },
  data: () => ({
    isRequestingSignIn: false,
    modal: true,
    promptInvalidLoginError: false,
    form: {
      email: '',
      password: ''
    }
  }),
  mounted() {
    this.form.email = this.email || ''
  },
  methods: {
    async onSubmit() {
      if (this.isRequestingSignIn) {
        return
      }

      this.$v.form.$touch()

      if (this.$v.form.$error) {
        return
      }

      this.isRequestingSignIn = true

      try {
        await this.$store.dispatch('user/login', {
          ...this.form,
          exists: true
        })
      } catch (err) {
        this.promptInvalidLoginError = true
      }

      this.isRequestingSignIn = false
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required
      }
    }
  }
}
</script>
