import queryString from 'querystring-es3'
import trimStart from 'lodash/trimStart'
import FileModel from './File'

export default class Image extends FileModel {
  get fullSizeUrl() {
    return process.env.IMGIX_BASE_URL + '/' + trimStart(this.path, '/')
  }

  get largeSizeUrl() {
    return this.resizedUrl(1200, 1200)
  }

  get thumbnailSizeUrl() {
    return this.croppedUrl(200, 200, 1.5)
  }

  get listingSizeUrl() {
    return this.croppedUrl(1200, 675, 1.5)
  }

  get coverSizeUrl() {
    return this.resizedUrl(1600, 900)
  }

  get portaitSizeUrl() {
    return this.resizedUrl(600, 1200)
  }

  get socialSizeUrl() {
    // MUST BE THE SAME TRANSFORMATION AS ON BACKEND
    return this.fullSizeUrl + '?fm=jpg&max-w=2000&max-h=1000'
  }

  resizedUrl(width = null, height = null, scale = 1.0) {
    const params = {}

    if (width) {
      params.w = Math.round(width * scale)
    }

    if (height) {
      params.h = Math.round(height * scale)
    }

    if (scale > 1.0) {
      params.q = 60
    }

    return this.fullSizeUrl + '?' + queryString.stringify(params)
  }

  croppedUrl(width = null, height = null, scale = 1.0) {
    const params = {
      fit: 'crop',
      w: Math.round(width * scale),
      h: Math.round(height * scale),
      auto: 'format'
    }

    if (scale > 1.0) {
      params.q = 60
    }

    return this.fullSizeUrl + '?' + queryString.stringify(params)
  }
}
