<template>
  <div class="form-password-checker">
    <p class="mb-10">Password must contain:</p>

    <div class="form-password-checker__requirements">
      <div
        class="form-password-checker__requirements--item"
        :class="{ valid: checkResult.hasUpperCase }"
      >
        <span></span>
        <p>upper-case</p>
      </div>
      <div
        class="form-password-checker__requirements--item"
        :class="{ valid: checkResult.hasEightChars }"
      >
        <span></span>
        <p>minimum 8 characters</p>
      </div>
      <div
        class="form-password-checker__requirements--item"
        :class="{ valid: checkResult.hasNumber }"
      >
        <span></span>
        <p>number</p>
      </div>
      <div
        class="form-password-checker__requirements--item"
        :class="{ valid: checkResult.hasSpecialChars }"
      >
        <span></span>
        <p>special character (eg. ~!@#$%&)</p>
      </div>
    </div>
  </div>
</template>

<script>
import passwordChecker from '@/services/passwordChecker'

export default {
  name: 'FormPasswordChecker',
  props: {
    password: {
      type: String
    }
  },
  computed: {
    checkResult() {
      return passwordChecker(this.password)
    }
  }
}
</script>

<style lang="scss">
.form-password-checker {
  padding: 0 rem(6px);

  &__requirements {
    display: grid;
    grid-template-columns: rem(150px) 100%;

    @include media-breakpoint-down(sm) {
      grid-template-columns: 100%;
    }

    &--item {
      > * {
        display: inline-block;
      }

      span {
        background-color: $gray-600;
        width: rem(12px);
        height: rem(12px);
        border-radius: rem(12px);
        margin-right: rem(8px);
      }

      p {
        color: $gray-600;
      }

      &.valid {
        span {
          background-color: $green;
        }

        p {
          color: $dark-blue;
        }
      }
    }
  }
}
</style>
