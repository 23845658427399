import Visibility from 'visibilityjs'

export default function({ store }) {
  /**
   * add Page Visibility listener to trigger a 'CHECK_AUTH' when tab becomes visible.
   */
  Visibility.change((evt, state) => {
    if (state === 'visible') {
      store.dispatch('user/checkAuth')
    }
  })
}
