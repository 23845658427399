import Vue from 'vue';
import Multiselect from 'vue-multiselect';
import Vuelidate from 'vuelidate';
import * as VueGoogleMaps from 'vue2-google-maps';

import { directive as onClickOutside } from 'vue-on-click-outside';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utcPlugin from 'dayjs/plugin/utc';

import capitalize from '@/filters/capitalize';
import pluralize from '@/filters/pluralize';
import date from '@/filters/date';
import approx from '@/filters/approx';

import syncMixin from '@/mixins/sync';
import globalMixin from '@/mixins/global';

import Vue2TouchEvents from 'vue2-touch-events';

dayjs.extend(relativeTime);
dayjs.extend(utcPlugin);

Vue.component('multiselect', Multiselect);
Vue.use(Vuelidate);

Vue.mixin(syncMixin);
Vue.mixin(globalMixin);

Vue.filter('capitalize', capitalize);
Vue.filter('pluralize', pluralize);
Vue.filter('date', date);
Vue.filter('approx', approx);

Vue.directive('on-click-outside', onClickOutside);

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.GOOGLE_MAPS_API_KEY,
    libraries: 'places'
  },
  installComponents: true
});

Vue.use(Vue2TouchEvents);
