import find from 'lodash/find'

/**
 * middleware for all `/client/investors/:investor/....` routes.
 */
export default function({ store, params, redirect }) {
  const user = store.getters['user/data']

  // admins have access to all investors.
  if (user && user.isAdmin) {
    return
  }

  if (!store.getters['user/isLoggedIn']) {
    return redirect('/investors/join')
  }

  // not manager of the requested investor redirect to own investor page.
  if (!find(user.investors, { key: params.investor })) {
    return redirect('/investors/join')
  }
}
