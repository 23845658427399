import BaseModel from './BaseModel'

export default class InvestorInvestment extends BaseModel {
  get displayName() {
    return this.company ? this.company.name : this.name
  }

  get logoImageUrl() {
    return this.company ? this.company.logoImageUrl : null
  }

  get websiteUrl() {
    if (/^https?:\/\//.test(this.website)) {
      return this.website
    }

    return 'http://' + this.website
  }

  getUrl(store) {
    if (this.company) {
      let url = this.company.url

      const platform = store.state.platform

      if (!this.company.platform[platform]) {
        if (platform === 'plusimpact') {
          url = process.env.THEHUB_URL + url
        } else {
          url = process.env.PLUSIMPACT_URL + url
        }
      }

      return url
    }

    return this.websiteUrl
  }

  get isEditable() {
    return !this.company
  }
}

InvestorInvestment.mapping = {}
