/* global vgo, CookieInformation */

import Vue from 'vue'

export default function({ store, app }) {
  if (!process.env.ACTIVE_CAMPAIGN_ACCOUNT_ID) {
    console.log('active-campaign: missing account-id')
    return
  }

  ;(function(e, t, o, n, p, r, i) {
    e.visitorGlobalObjectAlias = n
    e[e.visitorGlobalObjectAlias] =
      e[e.visitorGlobalObjectAlias] ||
      function() {
        ;(e[e.visitorGlobalObjectAlias].q =
          e[e.visitorGlobalObjectAlias].q || []).push(arguments)
      }
    e[e.visitorGlobalObjectAlias].l = new Date().getTime()
    r = t.createElement('script')
    r.type = 'text/javascript'
    r.async = true
    r.setAttribute('data-category-consent', 'cookie_cat_marketing')
    r.setAttribute('data-consent-src', o)
    i = t.getElementsByTagName('script')[0]
    i.parentNode.insertBefore(r, i)
  })(window, document, 'https://prism.app-us1.com/prism.js', 'vgo')

  window.addEventListener(
    'CookieInformationConsentGiven',
    function() {
      if (CookieInformation.getConsentGivenFor('cookie_cat_marketing')) {
        vgo('setAccount', process.env.ACTIVE_CAMPAIGN_ACCOUNT_ID)
        vgo('setTrackByDefault', true)

        /**
         * send on each page navigation.
         */
        app.router.afterEach(() => {
          const user = store.getters['user/data']
          if (user) {
            vgo('setEmail', user.email)
          }

          // Fire tracking after the nextTick or it will still register the previous route
          // https://github.com/MatteoGabriele/vue-analytics/issues/44
          Vue.nextTick().then(() => {
            vgo('process')
          })
        })
      }
    },
    false
  )

  console.log('active-campaign: plugin installed')
}
